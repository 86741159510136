// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Tabs
\* ------------------------------------------------------------------------ */

.tab-menu-button {
    .tab-wrapper.-accordion > & {
        display: none;
    }
}

.tab-content {
    padding: remify(44 0, 16);
}
