// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Header
\* ------------------------------------------------------------------------ */

.header-wrapper {
    @include layer("header");

    background: lighten($dark, 7.8431);
    font-size: remify(16, 16);
    padding: remify(20, 16);

    .-nav-active & {
        position: static;
    }
}

.header {
    @include clear;

    margin: 0 auto;
    max-width: remify($site-width, 16);
}

.header .logo {
    margin: 0 auto;
    max-width: remify(280, 16);
    padding: remify(5, 16);
    width: 100%;
}

.header .menu-wrapper {
    float: right;

    &.-nav {
        background: none;
    }
}
