// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Page
\* ------------------------------------------------------------------------ */

html,
body {
    width: 100%;
}

html {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}

body {
    background: $background;
}

html.-nav-active,
.-nav-active body {
    height: 100%;
    overflow: hidden;
    width: 100%;
}

.page-wrapper {
    background: $background;
    font-size: 16px;
    font-size: remify(16);
    left: 0;
    min-height: 100%;
    position: relative;
    transition: box-shadow 0.15s, left 0.15s;
    transform: translateZ(0); // force GPU rendering
    width: 100%;
    z-index: 2;

    .-nav-active & {
        box-shadow: remify(0 0 14 2.38, 16) transparentize($dark, 0.65);
        height: 100%;
        left: calc(-100% + #{remify(67.5, 16)});
        overflow: hidden;
        width: 100%;
    }

    &:before {
        background: transparentize($dark, 0.5);
        bottom: 100%;
        content: "\0020";
        display: block;
        left: 0;
        opacity: 0;
        position: absolute;
        right: 100%;
        top: 0;
        transition: bottom 0s 0.15s, opacity 0.15s, right 0s 0.15s;
        transform: translateZ(0); // force GPU rendering
        z-index: 998;
    }

    .-nav-active &:before {
        bottom: 0;
        opacity: 1;
        right: 0;
        transition: opacity 0.15s;
    }
}

// IE snap fix

@-ms-viewport {
    width: device-width;
}
