// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Article List
\* ------------------------------------------------------------------------ */

.article-list-header {
    overflow: hidden;

    &.-box {
        margin: 0 auto;
        max-width: remify(280, 16);
    }
}

.article-list-header .heading.-sub {
    margin: remify(0 0 16, 22.5);
}

.article-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.article-list-item {
    display: block;
}

.article-list-item:not(:last-child) {
    margin-bottom: remify(20, 16);
}

.article-list-item:not(:last-child) .article-card-content {
    margin-bottom: remify(20, 16);
}

.article-list-footer {
    margin: remify(20 0 0, 16);

    &.-box {
        margin-bottom: remify(20, 16);
        margin-left: auto;
        margin-right: auto;
        max-width: remify(280, 16);
    }
}

.article-list-footer .heading {
    & {
        margin-bottom: 0;
        text-align: right;
    }

    &.-alt {
        text-align: left;
    }
}
