// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Logo
\* ------------------------------------------------------------------------ */

.logo {
    display: block;
}

.logo img {
    display: block;
    width: 100%;
}
