// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Tribe Events
\* ------------------------------------------------------------------------ */

// bar

#tribe-events-bar #tribe-bar-form #tribe-bar-views .tribe-bar-views-inner .tribe-bar-views-list li a {
    & {
        text-decoration: none;
    }
}

#tribe-events-bar #tribe-bar-form #tribe-bar-views .tribe-bar-views-inner .tribe-bar-views-list li a span:before {
    & {
        top: 2px !important; // ffs Tribe -_-
    }
}

#tribe-events-bar #tribe-bar-form #tribe-bar-views .tribe-bar-views-inner .tribe-bar-views-list li a span.tribe-icon-month:before {
    & {
        @include icon("calendar", true); // ffs Tribe -_-
    }
}

#tribe-events-bar #tribe-bar-form #tribe-bar-views .tribe-bar-views-inner .tribe-bar-views-list li a span.tribe-icon-list:before {
    & {
        @include icon("list", true); // ffs Tribe -_-
    }
}

#tribe-events-bar #tribe-bar-form #tribe-bar-views .tribe-bar-views-inner .tribe-bar-views-list li a span.tribe-icon-day:before {
    & {
        @include icon("clock-o", true); // ffs Tribe -_-
    }
}

#tribe-events-bar #tribe-bar-form .tribe-bar-filters .tribe-bar-filters-inner label {
    & {
        padding-right: 10px;
    }
}

// month

.tribe-events-calendar {
    & {
        color: $foreground;
        font-family: $body-font;
        font-size: remify(16, 16);
        font-weight: 300;
        line-height: remify(24, 16);
        table-layout: fixed;
        width: 100%;
    }
}

.tribe-events-calendar td {
    & {
        border: remify(3) solid $background_alt;
        height: remify(60, 16);
        padding: remify(8 10, 16);
        position: relative;
        text-align: center;
        vertical-align: top;
        width: (100% / 7);
    }

    @media screen and (max-width: $s-break - (1 / 16)) {
        &.mobile-active {
            background-color: lighten($accent, 20);
        }
    }

    &.tribe-events-othermonth {
        background: darken($background, 2.5);
    }
}

.tribe-events-calendar div[id*=tribe-events-daynum-],
.tribe-events-calendar div[id*=tribe-events-daynum-] a {
    & {
        text-align: right;
    }
}

.tribe-events-calendar div[id*=tribe-events-daynum-] a {
    & {
        color: $foreground;
        text-decoration: none;
        transition: color 0.15s;
    }

    &:hover {
        color: lighten($foreground, 10);
    }
}


.tribe-events-calendar .tribe-events-month-event-title {
    & {
        display: none;
        font-size: remify(14, 16);
        font-weight: 400;
        line-height: normal;
        margin: 0;
    }
}

.tribe-events-calendar a {
    & {
        font-weight: 400 !important;
    }
}

.tribe-events-calendar .tribe-events-tooltip {
    & {
        background: $primary;
        bottom: 100% !important; // override inline style
        left: 50%;
        padding: remify(16 20 13, 16);
        position: absolute;
        transform: translateX(-50%);
        width: 200%;
    }

    &::after {
        border-left: remify(10) solid transparent;
        border-right: remify(10) solid transparent;
        border-top: remify(10) solid $primary;
        content: "\0020";
        display: block;
        height: 0;
        left: 50%;
        position: absolute;
        top: 100%;
        transform: translateX(-50%);
        width: 0;
    }

    * {
        color: $light !important;
    }
}

.tribe-events-calendar .tribe-events-tooltip .entry-title {
    & {
        color: $light;
        font-size: remify(18, 16);
        font-family: $heading-font;
        font-weight: 700;
        line-height: remify(22, 18);
        margin: 0;
        text-transform: uppercase;
    }
}

.tribe-events-calendar .tribe-events-tooltip .tribe-events-event-body {
    & {
        font-size: remify(16, 16);
        padding: 0;
    }
}

.tribe-events-calendar .tribe-events-tooltip .tribe-event-duration {
    & {
        color: $light;
        display: inline;
        font-family: $body-font;
        font-size: remify(16, 16);
        font-weight: 300;
        line-height: remify(20, 16);
        margin: 0;
        text-transform: uppercase;
    }
}

.tribe-events-calendar .tribe-events-tooltip .tribe-events-event-body p {
    & {
        color: $light;
        font-size: remify(14, 16);
        margin: 0;
    }
}

.tribe-events-calendar .tribe-events-tooltip .link {
    & {
        color: $light;
    }
}

.tribe-events-button {
    @extend .button;
}

// list

.tribe-events-list-separator-month {
    & {
        color: $primary !important; // ffs Tribe -_-
        display: block;
        font-family: $heading-font;
        font-size: remify(20, 16) !important; // ffs Tribe -_-
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
        margin: remify(20 0, 20);
        position: relative;
    }

    &:after {
        background: none !important; // ffs Tribe -_-
        border-bottom: remify(3) solid $background_alt;
        content: "\0020";
        display: block;
        height: 0 !important; // ffs Tribe -_-
        left: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        z-index: 1;
    }

    > span {
        background: $background;
        display: inline-block;
        padding: remify(0 20, 20);
        position: relative;
        z-index: 2;
    }

    + .article-list-item > .article-card {
        border-top: 0 !important;
    }
}

.post-type-archive-tribe_events .article-list-item:not(:last-of-type) > .article-card:not(.-box),
.post-type-archive-tribe_events :not([class^="col"]) > .article-card:not(.-box):not(:last-of-type),
.post-type-archive-tribe_events [class^="col"]:not(:last-of-type) > .article-card:not(.-box) {
    & {
        border-bottom: 0;
        padding-bottom: 0;
    }
}

// single

.single-tribe_events .content .article-card-meta-list-item .address {
    & {
        text-align: left;
    }
}

.single-tribe_events .content .article-card-meta-list-item .address a {
    & {
        display: block;
    }
}

.single-tribe_events .content .tribe-events-cal-links {
    & {
        @extend .text;

        margin-top: remify(16, 16);
    }
}

.single-tribe_events .content .tribe-events-cal-links a {
    & {
        color: $light;
        margin-right: remify(20, 16);
    }
}
