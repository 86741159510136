@charset "UTF-8";
/* ------------------------------------------------------------------------ * * Helpers                                                                  *
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Colors                                                                   *
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Fonts                                                                    *
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Widths                                                                   *
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Break Points                                                             *
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Layers                                                                   *
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * FontAwesome Icons (http://fortawesome.github.io/Font-Awesome/icons/)     *
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Functions                                                                *
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Mixins                                                                   *
\* ------------------------------------------------------------------------ */
._block {
  display: block !important; }

._flex {
  display: flex !important; }

._inline {
  display: inline !important; }

._visually-hidden,
._visuallyhidden {
  left: -999em !important;
  position: absolute !important;
  top: -999em !important; }

._accent {
  color: #98BE50 !important; }

._dark {
  color: #000000 !important; }

._light {
  color: #FFFFFF !important; }

._textcenter {
  text-align: center !important; }

._textleft {
  text-align: left !important; }

._textright {
  text-align: right !important; }

._center {
  margin-left: auto !important;
  margin-right: auto !important; }

._left {
  float: left !important; }

._right {
  float: right !important; }

._lowercase {
  text-transform: lowercase !important; }

._uppercase {
  text-transform: uppercase !important; }

._nomargin {
  margin: 0 !important; }

._nopadding {
  padding: 0 !important; }

/* ------------------------------------------------------------------------ * * Base
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Normalize
\* ------------------------------------------------------------------------ */
html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  margin: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden],
template {
  display: none; }

a {
  background: transparent; }

a:active,
a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

pre {
  overflow: auto; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  -webkit-appearance: textfield; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

::-webkit-outer-spin-button,
::-webkit-inner-spin-button,
::-webkit-calendar-picker-indicator {
  appearance: none;
  display: none;
  margin: 0;
  -webkit-appearance: none; }

input[type="number"] {
  -moz-appearance: textfield; }

input {
  border-radius: 0;
  -webkit-appearance: none; }

address {
  font-style: normal; }

/* ------------------------------------------------------------------------ * * Gridly (Modified from https://github.com/IonicaBizau/gridly)
\* ------------------------------------------------------------------------ */
.row {
  display: flex;
  flex-direction: column; }

.col {
  flex: 0 0 auto; }

@media screen and (min-width: 48rem) {
  .row {
    flex-direction: row; }
    .row.-wrap {
      flex-wrap: wrap; }
    .row.-between {
      justify-content: space-between; }
    .row.-top {
      align-items: flex-start; }
    .row.-center {
      align-items: center; }
    .row.-bottom {
      align-items: flex-end; }
    .row.-padded {
      width: calc(100% + 1.25em); }
    .row.-padded > [class^="col"] {
      padding-right: 1.25em; }
    .row [class^="col"].-padded-right {
      padding-right: 0.3125em; }
    .row [class^="col"].-padded-left {
      padding-left: 0.3125em; }
  .col {
    flex: 1 1 auto; }
  .col-fluid {
    flex: 1 1 0%; }
  .col-none {
    flex: 0 0 auto; }
  .col-tenth {
    flex: 0 0 10%; }
  .col-fifth {
    flex: 0 0 20%; }
  .col-quarter {
    flex: 0 0 25%; }
  .col-third {
    flex: 0 0 33.3333334%; }
  .col-half {
    flex: 0 0 50%; } }

/* ------------------------------------------------------------------------ * * Content
\* ------------------------------------------------------------------------ */
.heading {
  color: #98BE50;
  font-family: "museo-slab", "Museo Slab", "Times New Roman", "Times", serif;
  font-size: 1.5625em;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0em 0em 0.88em; }
  .heading.-alt {
    color: #000000; }
  .heading.-sub {
    font-size: 1.28125em; }
  .heading.-small {
    font-size: 0.94719em; }
  .heading a {
    color: inherit;
    text-decoration: none;
    transition: color 0.15s; }
  .heading:hover a {
    color: #ADD95B; }
  .heading.-alt:hover a {
    color: #343434; }

.text, .single-tribe_events .content .tribe-events-cal-links {
  color: #000000;
  font-family: "museo-slab", "Museo Slab", "Times New Roman", "Times", serif;
  font-size: 1em;
  font-weight: 300;
  line-height: 1.5em;
  margin: 0em 0em 1.5em; }

.link, .pagination-block a, .post-header-title a, .post-header-subtitle a {
  color: #98BE50;
  text-decoration: none;
  transition: color 0.15s; }

.link:hover, .pagination-block a:hover, .post-header-title a:hover, .post-header-subtitle a:hover {
  color: #ADD95B; }

@media screen and (min-width: 48rem) {
  .heading {
    font-size: 2.8125em; }
    .heading.-sub {
      font-size: 1.625em; }
    .heading.-small {
      font-size: 1.25em; }
  .text, .single-tribe_events .content .tribe-events-cal-links {
    font-size: 1.125em;
    line-height: 1.66667em;
    margin-bottom: 1.66667em; } }

/* ------------------------------------------------------------------------ * * Forms
\* ------------------------------------------------------------------------ */
.input {
  background: #E9E9E9;
  border-radius: 0;
  border: 0.0625rem solid #E9E9E9;
  color: #000000;
  display: block;
  font-family: "museo-slab", "Museo Slab", "Times New Roman", "Times", serif;
  font-size: 0.75em;
  font-weight: 400;
  line-height: 1.5em;
  margin: 0em 0em 0.83333em;
  outline: 0;
  padding: 0.5em 0.66667em;
  transition: border-color 0.15s, color 0.15s;
  width: 100%;
  /* remove weird user agent styles */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

.input:focus, .input:hover {
  border-color: #d0d0d0; }

@media screen and (min-width: 48rem) {
  .input {
    font-size: 0.875em; } }

.input.-select {
  cursor: pointer;
  text-indent: 0.01px;
  text-overflow: ""; }
  .input.-select::-ms-expand {
    display: none; }

.input.-select.-arrow {
  /* bgImage: ignore */
  background: url('data:image/svg+xml;utf8,<svg width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1408 1088q0 26-19 45l-448 448q-19 19-45 19t-45-19l-448-448q-19-19-19-45t19-45 45-19h896q26 0 45 19t19 45zm0-384q0 26-19 45t-45 19h-896q-26 0-45-19t-19-45 19-45l448-448q19-19 45-19t45 19l448 448q19 19 19 45z"/></svg>');
  background-position: center right 0.66667em;
  background-repeat: no-repeat;
  background-size: 1em 1em;
  padding-right: 2.5em; }

.input.-checkbox,
.input.-radiobutton {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 0.83333em;
  display: inline-block;
  height: 1.33333em;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  width: 1.33333em;
  -webkit-appearance: none;
  appearance: none; }

.input.-checkbox.-radiobutton,
.input.-radiobutton.-radiobutton {
  border-radius: 50%; }

.input.-checkbox.-checkbox:checked,
.input.-radiobutton.-checkbox:checked {
  /* bgImage: ignore */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewbox='0 0 32 32'%3E%3Cpath d='M32,8.5c0,0.5-0.2,1-0.6,1.4L16.5,24.9l-2.8,2.8c-0.4,0.4-0.9,0.6-1.4,0.6c-0.5,0-1-0.2-1.4-0.6l-2.8-2.8l-7.5-7.5,C0.2,17,0,16.6,0,16c0-0.6,0.2-1,0.6-1.4l2.8-2.8c0.4-0.4,0.9-0.6,1.4-0.6c0.5,0,1,0.2,1.4,0.6l6.1,6.1L25.8,4.3,c0.4-0.4,0.9-0.6,1.4-0.6c0.6,0,1,0.2,1.4,0.6l2.8,2.8C31.8,7.5,32,8,32,8.5z' /%3E%3C/svg%3E"); }

.input.-checkbox.-radiobutton:checked,
.input.-radiobutton.-radiobutton:checked {
  /* bgImage: ignore */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewbox='0 0 32 32'%3E%3Cpath d='M29.9,8c1.4,2.5,2.1,5.1,2.1,8s-0.7,5.6-2.1,8s-3.4,4.4-5.8,5.8S18.9,32,16,32c-2.9,0-5.6-0.7-8-2.1S3.6,26.5,2.1,24,C0.7,21.6,0,18.9,0,16s0.7-5.6,2.1-8C3.6,5.5,5.5,3.6,8,2.1S13.1,0,16,0c2.9,0,5.6,0.7,8,2.1S28.4,5.5,29.9,8z' /%3E%3C/svg%3E"); }

.input.-checkbox + .text.-label, .single-tribe_events .content .input.-checkbox + .-label.tribe-events-cal-links,
.input.-radiobutton + .text.-label, .single-tribe_events .content
.input.-radiobutton + .-label.tribe-events-cal-links {
  display: inline-block; }

@media (pointer: coarse) {
  .input.-checkbox,
  .input.-radiobutton {
    font-size: 1.57143em; } }

/* ------------------------------------------------------------------------ * * User Content
\* ------------------------------------------------------------------------ */
.user-content {
  /* headings */
  /* general text */
  /* modifiers */
  /* tables */ }
  .user-content h1,
  .user-content h2,
  .user-content h3,
  .user-content h4,
  .user-content h5,
  .user-content h6 {
    color: #98BE50;
    font-family: "museo-slab", "Museo Slab", "Times New Roman", "Times", serif;
    font-weight: 700;
    margin: 0;
    text-transform: uppercase; }
  .user-content h1 {
    font-size: 1.625em; }
  .user-content h2,
  .user-content h3,
  .user-content h4,
  .user-content h5,
  .user-content h6 {
    font-size: 1.25em; }
  .user-content ol,
  .user-content p,
  .user-content table,
  .user-content ul {
    color: #000000;
    font-family: "museo-slab", "Museo Slab", "Times New Roman", "Times", serif;
    font-size: 1em;
    font-weight: 300;
    line-height: 1.5em;
    margin: 0em 0em 1.5em; }
    .user-content ol ol,
    .user-content ol p,
    .user-content ol table,
    .user-content ol ul,
    .user-content p ol,
    .user-content p p,
    .user-content p table,
    .user-content p ul,
    .user-content table ol,
    .user-content table p,
    .user-content table table,
    .user-content table ul,
    .user-content ul ol,
    .user-content ul p,
    .user-content ul table,
    .user-content ul ul {
      font-size: 1em; }
    .user-content ol strong,
    .user-content p strong,
    .user-content table strong,
    .user-content ul strong {
      font-weight: 700; }
  .user-content a:not(.button):not(.tribe-events-button) {
    color: #98BE50;
    text-decoration: none;
    transition: color 0.15s; }
    .user-content a:not(.button):not(.tribe-events-button):hover {
      color: #ADD95B; }
  .user-content table {
    display: block;
    overflow: auto;
    padding-bottom: 1px;
    position: relative;
    table-layout: fixed;
    width: 100%;
    -webkit-overflow-scrolling: touch; }
    .user-content table:after {
      content: "scroll for more \00BB";
      display: block;
      font-size: 0.75em;
      padding-right: 0.5em;
      text-align: right; }
  .user-content table td,
  .user-content table th {
    min-width: 12.5em;
    padding: 0.75em 1.875em 0.5em;
    vertical-align: top; }
  .user-content table tr:nth-of-type(even) td,
  .user-content table tr:nth-of-type(even) th {
    background: #E9E9E9; }
  .user-content table thead td,
  .user-content table thead th {
    background: #98BE50;
    color: #FFFFFF;
    font-family: "museo-slab", "Museo Slab", "Times New Roman", "Times", serif;
    font-size: 1.25em;
    padding: 0.65em 1.5em 0.4em;
    text-transform: uppercase; }
  .user-content > *:last-child {
    margin-bottom: 0 !important; }
  .user-content strong {
    font-weight: 700; }
  .user-content img {
    height: auto !important;
    max-width: 100%;
    width: auto !important; }

@media screen and (min-width: 48rem) {
  .user-content {
    /* general text */
    /* tables */ }
    .user-content h1 {
      font-size: 2.25em;
      margin: 0em 0em 0.61111em; }
    .user-content h2 {
      font-size: 1.75em;
      margin: 0em 0em 0.78571em; }
    .user-content h3 {
      font-size: 1.5em;
      margin: 0em 0em 0.66667em; }
    .user-content h4 {
      font-size: 1.375em;
      margin: 0em 0em 0.63636em; }
    .user-content h5 {
      font-size: 1.25em;
      margin: 0em 0em 0.6em; }
    .user-content ol,
    .user-content p,
    .user-content table,
    .user-content ul {
      font-size: 1.125em;
      line-height: 1.66667em;
      margin-bottom: 1.66667em; }
    .user-content table {
      display: table;
      font-size: 0.9375em;
      overflow: visible;
      padding: 0; }
      .user-content table:after {
        display: none; }
    .user-content [class^="col"]:not(:last-child) {
      padding-right: 1.25em; } }

/* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Address
\* ------------------------------------------------------------------------ */
.address > .address_title {
  color: #000000;
  font-family: "museo-slab", "Museo Slab", "Times New Roman", "Times", serif;
  font-size: 1.25em;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  text-transform: uppercase; }

.address > .address_text {
  color: #000000;
  font-family: "museo-slab", "Museo Slab", "Times New Roman", "Times", serif;
  font-size: 0.675em;
  font-weight: 700;
  line-height: 1.5em;
  margin: 0em 0em 0.5em;
  text-transform: uppercase; }

/* ------------------------------------------------------------------------ * * Align
\* ------------------------------------------------------------------------ */
.aligncenter,
.alignleft,
.alignright {
  display: block;
  margin: 0 auto 1.875em; }

/* ------------------------------------------------------------------------ * * Article Card
\* ------------------------------------------------------------------------ */
.article-card {
  overflow: hidden;
  width: 100%; }

.article-list-item:not(:last-of-type) > .article-card:not(.-box),
:not([class^="col"]) > .article-card:not(.-box):not(:last-of-type),
[class^="col"]:not(:last-of-type) > .article-card:not(.-box) {
  padding-bottom: 1.375em; }

.article-list-item:not(:first-of-type) > .article-card:not(.-box),
:not([class^="col"]) > .article-card:not(.-box):not(:first-of-type),
[class^="col"]:not(:first-of-type) > .article-card:not(.-box) {
  border-top: 0.1875rem solid #E9E9E9;
  padding-top: 1.375em; }

.article-card.-box {
  background: #FFFFFF;
  box-shadow: 0em 0.0625em 0.26562em 0.04688em rgba(0, 0, 0, 0.2);
  margin: 0 auto;
  max-width: 17.5em;
  position: relative; }

:not([class^="col"]) > .article-card.-box:not(:last-of-type),
[class^="col"]:not(:last-of-type) > .article-card.-box {
  margin-bottom: 1.5em; }

:not([class^="col"]) > .article-card.-box,
[class^="col"] > .article-card.-box {
  margin-bottom: 1.5em; }

.article-card-image {
  margin: 0em 0em 1.25em;
  position: relative; }

.article-card.-box > .article-card-image {
  margin: 0; }

.article-card-image img {
  display: block;
  height: auto !important;
  width: 100%; }

.article-card-image img.article-card-image-banner {
  left: 0;
  position: absolute;
  top: 0;
  width: auto; }

.article-card.-box .article-card-content {
  padding: 1.125em 1.25em; }

.article-card-title {
  color: #98BE50;
  display: inline;
  font-family: "museo-slab", "Museo Slab", "Times New Roman", "Times", serif;
  font-size: 1.125em;
  font-weight: 700;
  line-height: 1.2em;
  margin: 0;
  text-transform: uppercase;
  transition: color 0.15s; }

.article-card-title:hover {
  color: #ADD95B; }

.article-card-title a {
  color: inherit;
  text-decoration: none; }

.article-card.-box.-alt .article-card-title {
  display: block; }

.article-card-date {
  color: #343434;
  display: inline;
  font-family: "museo-slab", "Museo Slab", "Times New Roman", "Times", serif;
  font-size: 0.9375em;
  font-weight: 700;
  line-height: 1.33333em;
  margin: 0;
  text-transform: uppercase; }

.article-card-date:hover {
  color: #4e4e4e; }

.article-card-date a {
  color: inherit;
  text-decoration: none; }

.article-card.-box:not(.-alt) > .article-card-image + .article-card-content > .article-card-date {
  background: #343434;
  box-shadow: 0em 0.08333em 0.35417em 0.0625em rgba(0, 0, 0, 0.2);
  color: #FFFFFF;
  display: block;
  font-size: 0.75em;
  line-height: 1.5em;
  position: absolute;
  right: 1.66667em;
  text-align: center;
  transition: background 0.15s;
  top: 0; }

.article-card.-box:not(.-alt) > .article-card-image + .article-card-content > .article-card-date:hover {
  background: #4e4e4e; }

.article-card.-box:not(.-alt) > .article-card-image + .article-card-content > .article-card-date a {
  display: block;
  padding: 0.66667em 1.33333em; }

.article-card.-box.-alt .article-card-date {
  display: block; }

.article-card-date strong {
  font-weight: 700; }

.article-card.-box:not(.-alt) > .article-card-image + .article-card-content .article-card-date strong {
  display: block;
  color: #FFFFFF;
  font-family: "museo-slab", "Museo Slab", "Times New Roman", "Times", serif;
  font-size: 2em;
  font-weight: 300;
  line-height: 0.83333em; }

.article-card-excerpt {
  color: #000000;
  font-family: "museo-slab", "Museo Slab", "Times New Roman", "Times", serif;
  font-size: 1em;
  font-weight: 300;
  line-height: 1.5em;
  margin: 0; }

.article-card-excerpt:hover {
  color: #1a1a1a; }

.article-card-excerpt a {
  color: inherit;
  text-decoration: none; }

.article-card-excerpt.-tight {
  line-height: 1.125em; }

.article-card-meta-group:last-of-type {
  margin-bottom: 1.25em; }

.article-card-meta-title {
  color: #98BE50;
  font-family: "museo-slab", "Museo Slab", "Times New Roman", "Times", serif;
  font-size: 1.25em;
  font-weight: 700;
  margin: 0;
  text-transform: uppercase; }

.article-card-meta-title a {
  color: inherit;
  text-decoration: none; }

.article-card-meta-list {
  color: #000000;
  font-family: "museo-slab", "Museo Slab", "Times New Roman", "Times", serif;
  font-size: 1em;
  font-weight: 300;
  list-style: none;
  line-height: 1.5em;
  margin: 0em 0em 1.5em;
  padding: 0; }

.article-card-meta-list a {
  color: inherit;
  text-decoration: none; }

.article-card-meta-list-item {
  margin: 0.5em 0em; }

/* ------------------------------------------------------------------------ * * Article List
\* ------------------------------------------------------------------------ */
.article-list-header {
  overflow: hidden; }
  .article-list-header.-box {
    margin: 0 auto;
    max-width: 17.5em; }

.article-list-header .heading.-sub {
  margin: 0em 0em 0.71111em; }

.article-list {
  list-style: none;
  margin: 0;
  padding: 0; }

.article-list-item {
  display: block; }

.article-list-item:not(:last-child) {
  margin-bottom: 1.25em; }

.article-list-item:not(:last-child) .article-card-content {
  margin-bottom: 1.25em; }

.article-list-footer {
  margin: 1.25em 0em 0em; }
  .article-list-footer.-box {
    margin-bottom: 1.25em;
    margin-left: auto;
    margin-right: auto;
    max-width: 17.5em; }

.article-list-footer .heading {
  margin-bottom: 0;
  text-align: right; }

.article-list-footer .heading.-alt {
  text-align: left; }

/* ------------------------------------------------------------------------ * * Button
\* ------------------------------------------------------------------------ */
.button, .tribe-events-button {
  background: #98BE50;
  border: 0;
  color: #FFFFFF;
  display: inline-block;
  font-family: "museo-slab", "Museo Slab", "Times New Roman", "Times", serif;
  font-size: 1rem;
  font-weight: 700;
  padding: 0.5em 1.25em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: background 0.15s; }
  .button:hover, .tribe-events-button:hover {
    background: #ADD95B; }
  .button.-alt, .-alt.tribe-events-button {
    background: #000000;
    color: #98BE50; }
  .button.-alt:hover, .-alt.tribe-events-button:hover {
    background: #343434;
    color: #ADD95B; }
  .button.-big, .-big.tribe-events-button {
    box-shadow: 0em 0.04545em 0.22727em 0.04545em rgba(0, 0, 0, 0.2);
    font-family: "museo-slab", "Museo Slab", "Times New Roman", "Times", serif;
    font-size: 1.375rem;
    padding: 0.86364em 1.27273em 0.81818em;
    text-align: left; }
  .button.-block, .-block.tribe-events-button {
    display: block; }

/* ------------------------------------------------------------------------ * * Callouts
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Comments
\* ------------------------------------------------------------------------ */
#comments {
  padding-top: 1.25em; }

.comments-title.heading {
  font-size: 1.5em;
  margin: 0em 0em 0.5em; }

.comment-form-title.heading {
  font-size: 1.25em;
  margin: 0em 0em 0.5em; }

.comment-input {
  display: block;
  width: 100%; }

/* ------------------------------------------------------------------------ * * Copyright
\* ------------------------------------------------------------------------ */
.copyright {
  color: #E9E9E9;
  font-family: "museo-slab", "Museo Slab", "Times New Roman", "Times", serif;
  font-size: 0.675em;
  font-weight: 700;
  line-height: normal;
  margin: 0em 0em 1em;
  text-transform: uppercase; }

/* ------------------------------------------------------------------------ * * Divider
\* ------------------------------------------------------------------------ */
.divider {
  background: #E9E9E9;
  border: 0;
  height: 0.1875rem;
  margin: 1.375em 0em; }

/* ------------------------------------------------------------------------ * * Featured Image
\* ------------------------------------------------------------------------ */
.featured-image {
  margin: 0; }

.featured-image img {
  box-shadow: 0em 0.0625em 0.26562em 0.04688em rgba(0, 0, 0, 0.2);
  display: block;
  height: auto !important;
  width: 100%; }

/* ------------------------------------------------------------------------ * * Hiders
\* ------------------------------------------------------------------------ */
.show-xxs, .show-xs, .show-s, .show-m, .show-l, .show-xl, .show-xxl {
  display: none !important; }

/* ------------------------------------------------------------------------ * * iframe
\* ------------------------------------------------------------------------ */
.iframe {
  display: block;
  height: 0;
  position: relative;
  width: 100%; }
  .iframe iframe {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%; }

/* ------------------------------------------------------------------------ * * Logo
\* ------------------------------------------------------------------------ */
.logo {
  display: block; }

.logo img {
  display: block;
  width: 100%; }

/* ------------------------------------------------------------------------ * * Menu Button
\* ------------------------------------------------------------------------ */
.menu-button {
  background: none;
  border: 0;
  color: #98BE50;
  font-family: "museo-slab", "Museo Slab", "Times New Roman", "Times", serif;
  font-size: 1.25em;
  font-weight: 700;
  height: 1.75em;
  line-height: normal;
  margin: 0;
  padding: 0;
  position: relative;
  text-transform: uppercase;
  transition: color 0.15s;
  transform: translateZ(0);
  width: 1.75em;
  z-index: 999; }
  .-nav-active .menu-button {
    color: #FFFFFF; }
  .menu-button:before, .menu-button:after {
    background: currentColor;
    bottom: 0;
    content: "\0020";
    display: block;
    height: 0.18182em;
    margin: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: box-shadow 0.15s 0.15s, top 0.15s 0.15s, transform 0.15s;
    width: 1em; }
    .-nav-active .menu-button:before, .-nav-active .menu-button:after {
      transition: box-shadow 0.15s, top 0.15s, transform 0.15s 0.15s; }
  .menu-button:before {
    box-shadow: 0em 0.31818em 0em 0em currentColor;
    top: -0.68182em; }
    .-nav-active .menu-button:before {
      box-shadow: 0 0 0 0 transparent;
      transform: rotate(45deg);
      top: 0; }
  .menu-button:after {
    top: 0.54545em; }
    .-nav-active .menu-button:after {
      transform: rotate(-45deg);
      top: 0; }

/* ------------------------------------------------------------------------ * * Menu List
\* ------------------------------------------------------------------------ */
.menu-title {
  color: #98BE50;
  font-family: "museo-slab", "Museo Slab", "Times New Roman", "Times", serif;
  font-size: 1.25em;
  font-weight: 700;
  margin: 0em 0em 0.4em;
  text-transform: uppercase; }

.menu-list {
  *zoom: 1;
  list-style: none;
  margin: 0;
  padding: 0; }
  .menu-list:before, .menu-list:after {
    content: "";
    display: table; }
  .menu-list:after {
    clear: both; }
  .menu-wrapper:not(.-vertical) :not(.menu-mega) > .menu-list.-sub-menu,
  .menu-wrapper:not(.-vertical) .menu-list .menu-mega {
    left: -999em;
    opacity: 0;
    position: absolute;
    top: -999em;
    transition: left 0s 0.15s, opacity 0.15s, top 0s 0.15s;
    width: 12.5em; }
  .menu-wrapper:not(.-vertical) :not(.menu-mega) .menu-item:hover > .menu-list.-sub-menu,
  .menu-wrapper:not(.-vertical) .menu-list .menu-item:hover > .menu-mega {
    left: 0;
    opacity: 1;
    top: 100%;
    transition: left 0s 0.15s, opacity 0.15s 0.15s, top 0s 0.15s; }
  .menu-wrapper:not(.-vertical) > .menu-list > .menu-item.-right > .menu-list.-sub-menu,
  .menu-wrapper:not(.-vertical) .menu-list .menu-item.-right .menu-mega {
    left: auto;
    right: 999em;
    transition: right 0s 0.15s, opacity 0.15s, top 0s 0.15s; }
  .menu-wrapper:not(.-vertical) > .menu-list > .menu-item.-right:hover > .menu-list.-sub-menu,
  .menu-wrapper:not(.-vertical) > .menu-list > .menu-item.-right:hover > .menu-mega {
    left: auto;
    right: 0;
    transition: right 0s 0.15s, opacity 0.15s 0.15s, top 0s 0.15s; }
  .menu-wrapper:not(.-vertical) .menu-list .menu-mega {
    display: flex;
    width: auto; }
  .menu-wrapper:not(.-vertical) .menu-list .menu-mega > .menu-list.-sub-menu {
    flex: none;
    width: 14.0625em; }
  .menu-wrapper:not(.-vertical) .menu-list.-sub-menu .menu-list.-sub-menu {
    left: auto;
    opacity: 1;
    position: static;
    top: auto;
    width: auto; }

.menu-item {
  float: left;
  position: relative; }
  .menu-wrapper.-vertical .menu-item {
    display: flex;
    float: none;
    flex-wrap: wrap;
    position: static; }
  .menu-wrapper:not(.-vertical) .menu-list.-sub-menu .menu-item {
    float: none; }

.menu-link {
  display: block;
  font-size: 1em;
  padding: 0.73853em;
  text-decoration: none;
  transition: color 0.15s;
  width: 100%; }
  .menu-wrapper.-vertical .menu-link:nth-last-child(n+3) {
    flex: 1 1 auto;
    width: 0; }

.menu-toggle {
  background: none;
  border: 0;
  color: #000000;
  flex: none;
  font-size: 1em;
  height: 2.72727em;
  margin: 0;
  padding: 0;
  position: relative;
  text-indent: -999em;
  transition: color 0.15s;
  width: 2.72727em; }
  .menu-toggle:before {
    border-bottom: 0.27273em solid transparent;
    border-left: 0.27273em solid currentColor;
    border-top: 0.27273em solid transparent;
    content: "\0020";
    display: block;
    height: 0;
    left: 50%;
    position: absolute;
    text-indent: 0;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 0; }
  .menu-toggle:hover {
    color: #0d0d0d; }
  .menu-toggle.-close {
    position: absolute;
    right: 0;
    top: 0; }
  .menu-toggle.-close:before, .menu-toggle.-close:after {
    background: currentColor;
    border: 0;
    bottom: 0;
    content: "\0020";
    display: block;
    height: 0.13223em;
    margin: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: none;
    width: 1em; }
  .menu-toggle.-close:before {
    transform: rotate(-45deg); }
  .menu-toggle.-close:after {
    transform: rotate(45deg); }

/* nav varient */
.menu-wrapper.-nav {
  background: #FFFFFF; }

.menu-list {
  /* vertical nav */ }
  .menu-wrapper.-nav:not(.-vertical) .menu-list {
    font-family: "museo-slab", "Museo Slab", "Times New Roman", "Times", serif;
    font-size: 1.375em;
    font-weight: 700;
    text-transform: uppercase; }
  .menu-wrapper.-nav:not(.-vertical) .menu-list:not(.-sub-menu) > .menu-item > .menu-list.-sub-menu,
  .menu-wrapper.-nav:not(.-vertical) .menu-list:not(.-sub-menu) > .menu-item > .menu-mega > .menu-list.-sub-menu {
    background: #FFFFFF;
    font-size: 0.81818em;
    line-height: 1.11111em;
    padding: 0.72727em;
    width: 14.31818em; }
  .menu-wrapper.-nav:not(.-vertical) .menu-list:not(.-sub-menu) > .menu-item > .menu-mega > .menu-list.-sub-menu:first-child {
    padding-left: 1.45455em; }
  .menu-wrapper.-nav:not(.-vertical) .menu-list:not(.-sub-menu) > .menu-item > .menu-mega > .menu-list.-sub-menu:last-child {
    padding-right: 1.45455em; }
  .menu-wrapper.-nav:not(.-vertical) .menu-list:not(.-sub-menu) > .menu-item > .menu-mega > .menu-list.-sub-menu:only-child {
    width: 16.59091em; }
  .menu-wrapper.-nav:not(.-vertical) .menu-list:not(.-sub-menu) > .menu-item:hover > .menu-list.-sub-menu,
  .menu-wrapper.-nav:not(.-vertical) .menu-list:not(.-sub-menu) > .menu-item:hover > .menu-mega {
    top: calc(100% + 0.36364em); }
  .menu-wrapper.-nav:not(.-vertical) .menu-list:not(.-sub-menu) > .menu-item > .menu-mega:before {
    background: #98BE50;
    content: "\0020";
    display: block;
    height: 0.36364em;
    left: -999em;
    position: absolute;
    right: -999em;
    top: -0.36364em;
    z-index: -1; }
  .menu-wrapper.-nav:not(.-vertical) .menu-list:not(.-sub-menu) .menu-mega:after {
    background: rgba(138, 169, 80, 0.9);
    bottom: 0;
    content: "\0020";
    display: block;
    left: -999em;
    position: absolute;
    right: -999em;
    top: 0;
    z-index: -1; }
  .menu-wrapper.-nav:not(.-vertical) .menu-list.-sub-menu .menu-list.-sub-menu {
    background: none;
    font-size: 0.88889em;
    font-family: "museo-slab", "Museo Slab", "Times New Roman", "Times", serif;
    font-weight: 300;
    padding: 0;
    text-transform: none;
    width: 100%; }
  .menu-wrapper.-nav.-vertical .menu-list {
    font-family: "museo-slab", "Museo Slab", "Times New Roman", "Times", serif;
    font-size: 1.25em;
    font-weight: 700;
    text-transform: uppercase;
    padding: 0em 1.09091em; }
  .menu-wrapper.-nav.-vertical .menu-list:not(.-sub-menu) > .menu-item > .menu-list.-sub-menu {
    border-top: 0.36364em solid #98BE50; }
  .menu-wrapper.-nav.-vertical .menu-list.-sub-menu {
    background: #FFFFFF;
    bottom: 999em;
    height: 100%;
    font-size: 1em;
    left: -999em;
    opacity: 0;
    overflow: auto;
    padding: 1.36364em 1.13636em 0.36364em;
    position: absolute;
    right: 999em;
    transition: bottom 0s 0.15s, left 0s 0.15s, opacity 0.15s, right 0s 0.15s, top 0s 0.15s;
    top: -999em;
    width: 100%; }
  .menu-wrapper.-nav.-vertical .menu-list.-sub-menu.-active {
    bottom: 0;
    left: 0;
    opacity: 1;
    position: absolute;
    right: 0;
    transition: opacity 0.15s;
    top: 0;
    z-index: 999; }
  .menu-wrapper.-nav.-vertical .menu-list.-sub-menu .menu-list.-sub-menu {
    background: none;
    bottom: auto;
    font-family: "museo-slab", "Museo Slab", "Times New Roman", "Times", serif;
    font-size: 0.90909em;
    font-weight: 300;
    left: auto;
    opacity: 1;
    padding: 0;
    position: static;
    right: auto;
    text-transform: none;
    top: auto; }

.menu-wrapper.-nav:not(.-vertical) .menu-list:not(.-sub-menu) > .menu-item:after {
  border-bottom: 0.54545em solid #98BE50;
  border-left: 0.81818em solid transparent;
  border-right: 0.81818em solid transparent;
  bottom: 0;
  content: "\0020";
  display: block;
  left: 50%;
  margin-left: -0.81818em;
  opacity: 0;
  position: absolute;
  transition: opacity 0.15s; }

.menu-wrapper.-nav:not(.-vertical) .menu-list:not(.-sub-menu) > .menu-item:hover:after {
  opacity: 1;
  transition: opacity 0.15s 0.15s; }

.menu-wrapper.-nav.-vertical .menu-list:not(.-sub-menu) > .menu-item:not(:last-child) {
  border-bottom: 0.09091em solid #E9E9E9; }

.menu-wrapper.-nav .menu-list.-sub-menu .menu-item {
  padding: 0em 0em 0.86364em; }

.menu-wrapper.-nav .menu-list.-sub-menu .menu-list.-sub-menu .menu-item {
  padding: 0; }

.menu-wrapper.-nav .menu-link {
  color: #98BE50;
  padding: 1.90909em 1.59091em; }

.menu-wrapper.-nav .menu-link:hover {
  color: #a3c563; }

.menu-wrapper.-nav.-vertical .menu-link {
  padding: 0.72727em 0em 0.59091em; }

.menu-wrapper.-nav .menu-list.-sub-menu .menu-link {
  padding: 0.09091em 0em; }

.menu-wrapper.-nav .menu-list.-sub-menu .menu-list.-sub-menu .menu-link {
  color: #000000 !important;
  padding: 0.15em 0em; }

.menu-wrapper.-nav .menu-list.-sub-menu .menu-list.-sub-menu .menu-link:hover {
  color: #0d0d0d !important; }

.menu-wrapper.-nav .menu-toggle {
  color: #98BE50;
  margin-right: -1.22727em; }

.menu-wrapper.-nav .menu-toggle:hover {
  color: #a3c563; }

.menu-wrapper.-nav .menu-toggle.-close {
  margin: 0;
  right: 0.18182em;
  top: 0.72727em; }

/* subnav varient */
.menu-wrapper.-subnav .menu-list {
  color: #98BE50;
  font-family: "museo-slab", "Museo Slab", "Times New Roman", "Times", serif;
  font-size: 1.375em;
  font-weight: 700;
  text-transform: uppercase; }

.menu-wrapper.-subnav .menu-list.-sub-menu {
  color: #000000;
  font-family: "museo-slab", "Museo Slab", "Times New Roman", "Times", serif;
  font-size: 0.77273em;
  font-weight: 300;
  width: 100%; }

.menu-wrapper.-subnav .menu-link {
  padding: 0.27273em 0.18182em; }

.menu-wrapper.-subnav .menu-link:hover {
  color: #ADD95B; }

.menu-wrapper.-subnav .menu-list.-sub-menu .menu-link {
  padding: 0.35294em 0.82353em 0.11765em; }

.menu-wrapper.-subnav .menu-list.-sub-menu .menu-link:hover {
  color: #1a1a1a; }

.menu-wrapper.-subnav .menu-list.-sub-menu .menu-item.current_page_item .menu-link {
  background: #7c9f3b;
  color: #FFFFFF; }

/* social varient */
.menu-wrapper.-social .menu-list {
  color: #98BE50;
  font-family: "museo-slab", "Museo Slab", "Times New Roman", "Times", serif;
  font-size: 1em;
  font-weight: 300;
  text-align: center;
  text-transform: uppercase; }

.menu-wrapper.-social.-alt .menu-list {
  background: #686868;
  color: #ADD95B; }

.menu-wrapper.-social.-big .menu-list {
  font-size: 1.25em; }

.menu-wrapper.-social.-bold .menu-list {
  font-weight: 700; }

.menu-wrapper.-social.-flexed .menu-list {
  display: flex;
  font-size: 1.25em; }

.menu-wrapper.-social.-flexed .menu-item {
  float: none;
  flex: 1 1 auto;
  width: 0; }

.menu-wrapper.-social.-alt .menu-item {
  border-left: 0.03333em solid #ADD95B; }

.menu-wrapper.-social .menu-link {
  color: #98BE50;
  min-width: 2.8125em;
  padding: 0.40625em 0.375em 0.28125em; }

.menu-wrapper.-social .menu-link:hover {
  color: #aecc75; }

.menu-wrapper.-social.-alt .menu-link {
  color: #ADD95B;
  padding: 0.8125em 0.75em 0.5625em; }
  .menu-wrapper.-social.-alt .menu-link:hover {
    color: #c2e384; }

.menu-wrapper.-social.-alt .menu-link:hover {
  color: #c2e384; }

.menu-wrapper.-social .menu-item.-no-icon .menu-link {
  padding-bottom: 0.625em;
  padding-left: 1.5em;
  padding-right: 1.5em; }

.menu-wrapper.-social.-flexed .menu-link {
  min-width: 0;
  padding: 0.58125em 0.45em 0.28125em; }

.menu-wrapper.-social .menu-link .fa {
  background: #8c967f;
  border: 1px solid #aaaaaa;
  color: #FFFFFF;
  display: inline-block;
  font-size: 1em;
  height: 1.75em;
  line-height: 1.65em;
  margin: 0em 0.3125em 0em 0em;
  text-align: center;
  transition: background-color 0.15s, border-color 0.15s;
  width: 1.75em; }

.menu-wrapper.-social .menu-link:hover .fa {
  background-color: #99a18d;
  border-color: #b7b7b7; }

.menu-wrapper.-social .menu-link .fa:nth-last-child(2) {
  margin: 0; }

.menu-wrapper.-social .menu-item.-facebook .menu-link .fa {
  background-color: #1C5894;
  border-color: #1B5182; }

.menu-wrapper.-social .menu-item.-facebook .menu-link:hover .fa {
  background-color: #2065a9;
  border-color: #1f5e97; }

.menu-wrapper.-social .menu-item.-instagram .menu-link .fa {
  background-color: #665641;
  border-color: #45453e; }

.menu-wrapper.-social .menu-item.-instagram .menu-link:hover .fa {
  background-color: #76634b;
  border-color: #52524a; }

.menu-wrapper.-social .menu-item.-other .menu-link .fa {
  background-color: #98BE50;
  border-color: #7e8664; }

.menu-wrapper.-social .menu-item.-other .menu-link:hover .fa {
  background-color: #a3c563;
  border-color: #8b946f; }

.menu-wrapper.-social .menu-item.-kids-connection .menu-link .fa {
  background-color: #98BE50;
  border-color: #7e8664; }

.menu-wrapper.-social .menu-item.-kids-connection .menu-link:hover .fa {
  background-color: #a3c563;
  border-color: #8b946f; }

.menu-wrapper.-social .menu-item.-youtube .menu-link .fa {
  background-color: #AE0707;
  border-color: #751C24; }

.menu-wrapper.-social .menu-item.-youtube .menu-link:hover .fa {
  background-color: #c70808;
  border-color: #8a212a; }

.menu-link .menu-wrapper.-social.-alt .fa {
  background: none;
  border: 0;
  color: inherit;
  font-size: 1.125em;
  height: auto;
  line-height: inherit;
  width: auto; }

/* center variant */
.menu-list.-center {
  float: right;
  position: relative;
  right: 50%; }

.menu-list.-center .menu-item {
  left: 50%;
  position: relative; }

/* pagination variant */
.menu-list.-pagination .menu-item.-active .menu-link {
  color: #000000; }

.menu-list.-pagination .menu-item.-active .menu-link:hover {
  color: #1a1a1a; }

/* ------------------------------------------------------------------------ * * Ninja Forms
\* ------------------------------------------------------------------------ */
.nf-form-hp {
  left: -999em;
  position: absolute;
  top: -999em; }

.nf-field-container .pikaday__container {
  display: block; }

.nf-field-container .list-checkbox-wrap ul,
.nf-field-container .list-radio-wrap ul {
  font-size: 1em;
  list-style: none;
  margin-bottom: 0;
  padding: 0; }

.nf-field-container.label-above .field-wrap,
.nf-field-container.label-right .field-wrap,
.nf-field-container.label-below .field-wrap,
.nf-field-container.label-left .field-wrap {
  align-items: flex-start;
  display: flex; }

.nf-field-container.label-right .field-wrap .nf-field-label,
.nf-field-container.label-left .field-wrap .nf-field-label {
  flex: none; }

.nf-field-container.label-above .field-wrap .nf-field-element,
.nf-field-container.label-below .field-wrap .nf-field-element {
  width: 100%; }

.nf-field-container.label-right .field-wrap .nf-field-element,
.nf-field-container.label-left .field-wrap .nf-field-element {
  flex: 1 1 auto; }

.nf-field-container.label-above .field-wrap {
  flex-direction: column; }

.nf-field-container.label-right .nf-field-label {
  order: 2; }

.nf-field-container.label-right .nf-field-element {
  order: 1; }

.nf-field-container.label-below .field-wrap {
  flex-direction: column; }

.nf-field-container.label-below .nf-field-label {
  order: 2; }

.nf-field-container.label-below .nf-field-element {
  order: 1; }

/* ------------------------------------------------------------------------ * * Opinionated Tweaks
\* ------------------------------------------------------------------------ */
.nf-field-container .list-checkbox-wrap ul,
.nf-field-container .list-radio-wrap ul {
  font-size: 1em;
  margin-bottom: 1.5em; }

.nf-field-container .list-checkbox-wrap ul .text.-label, .nf-field-container .list-checkbox-wrap ul .single-tribe_events .content .-label.tribe-events-cal-links, .single-tribe_events .content .nf-field-container .list-checkbox-wrap ul .-label.tribe-events-cal-links,
.nf-field-container .list-radio-wrap ul .text.-label,
.nf-field-container .list-radio-wrap ul .single-tribe_events .content .-label.tribe-events-cal-links, .single-tribe_events .content
.nf-field-container .list-radio-wrap ul .-label.tribe-events-cal-links {
  margin-bottom: 0.25em; }

@media (pointer: coarse) {
  .nf-field-container .list-checkbox-wrap ul li:not(:last-child) .text.-label, .nf-field-container .list-checkbox-wrap ul li:not(:last-child) .single-tribe_events .content .-label.tribe-events-cal-links, .single-tribe_events .content .nf-field-container .list-checkbox-wrap ul li:not(:last-child) .-label.tribe-events-cal-links,
  .nf-field-container .list-radio-wrap ul li:not(:last-child) .text.-label,
  .nf-field-container .list-radio-wrap ul li:not(:last-child) .single-tribe_events .content .-label.tribe-events-cal-links, .single-tribe_events .content
  .nf-field-container .list-radio-wrap ul li:not(:last-child) .-label.tribe-events-cal-links {
    margin-bottom: 1.5em; } }

.nf-field-container .stars {
  margin-bottom: 1.5em; }

.nf-field-container .nf-user-content {
  margin-top: -1.25em; }

.nf-field-container .nf-error-msg {
  font-size: 0.625em;
  margin-bottom: 2.4em;
  margin-top: -2em; }

.nf-field-container .g-recaptcha {
  margin-bottom: 1.5em; }

.nf-field-container.label-above .nf-field-label > .text.-label, .nf-field-container.label-above .single-tribe_events .content .nf-field-label > .-label.tribe-events-cal-links, .single-tribe_events .content .nf-field-container.label-above .nf-field-label > .-label.tribe-events-cal-links {
  margin-bottom: 0.25em; }

.nf-field-container.checkbox-container.label-above .checkbox-wrap .text.-label, .nf-field-container.checkbox-container.label-above .checkbox-wrap .single-tribe_events .content .-label.tribe-events-cal-links, .single-tribe_events .content .nf-field-container.checkbox-container.label-above .checkbox-wrap .-label.tribe-events-cal-links {
  padding-left: 0;
  padding-top: 2em; }

.nf-field-container.checkbox-container.label-above .input.-checkbox + .text.-label.-checkbox:before, .nf-field-container.checkbox-container.label-above .single-tribe_events .content .input.-checkbox + .-label.-checkbox.tribe-events-cal-links:before, .single-tribe_events .content .nf-field-container.checkbox-container.label-above .input.-checkbox + .-label.-checkbox.tribe-events-cal-links:before {
  transform: none;
  top: 0; }

.nf-field-container.label-right .nf-field-label > .text.-label, .nf-field-container.label-right .single-tribe_events .content .nf-field-label > .-label.tribe-events-cal-links, .single-tribe_events .content .nf-field-container.label-right .nf-field-label > .-label.tribe-events-cal-links {
  margin-left: 0.625em; }

.nf-field-container.label-below .nf-field-label > .text.-label, .nf-field-container.label-below .single-tribe_events .content .nf-field-label > .-label.tribe-events-cal-links, .single-tribe_events .content .nf-field-container.label-below .nf-field-label > .-label.tribe-events-cal-links {
  margin-top: 0.25em; }

.nf-field-container.label-below .nf-field-element .input,
.nf-field-container.label-below .nf-field-element .stars,
.nf-field-container.label-below .list-checkbox-wrap ul,
.nf-field-container.label-below .list-radio-wrap ul {
  margin-bottom: 0;
  order: 1; }

.nf-field-container.checkbox-container.label-below .checkbox-wrap .text.-label, .nf-field-container.checkbox-container.label-below .checkbox-wrap .single-tribe_events .content .-label.tribe-events-cal-links, .single-tribe_events .content .nf-field-container.checkbox-container.label-below .checkbox-wrap .-label.tribe-events-cal-links {
  padding-left: 0;
  padding-bottom: 2em; }

.nf-field-container.checkbox-container.label-below .input.-checkbox + .text.-label.-checkbox:before, .nf-field-container.checkbox-container.label-below .single-tribe_events .content .input.-checkbox + .-label.-checkbox.tribe-events-cal-links:before, .single-tribe_events .content .nf-field-container.checkbox-container.label-below .input.-checkbox + .-label.-checkbox.tribe-events-cal-links:before {
  transform: none;
  bottom: 0;
  top: auto; }

.nf-field-container.label-left .nf-field-label > .text.-label, .nf-field-container.label-left .single-tribe_events .content .nf-field-label > .-label.tribe-events-cal-links, .single-tribe_events .content .nf-field-container.label-left .nf-field-label > .-label.tribe-events-cal-links {
  margin-right: 0.625em; }

.nf-field-container.checkbox-container.label-left .checkbox-wrap .text.-label, .nf-field-container.checkbox-container.label-left .checkbox-wrap .single-tribe_events .content .-label.tribe-events-cal-links, .single-tribe_events .content .nf-field-container.checkbox-container.label-left .checkbox-wrap .-label.tribe-events-cal-links {
  display: inline-block;
  padding-left: 0;
  padding-right: 2em;
  width: auto; }

.nf-field-container.checkbox-container.label-left .input.-checkbox + .text.-label.-checkbox:before, .nf-field-container.checkbox-container.label-left .single-tribe_events .content .input.-checkbox + .-label.-checkbox.tribe-events-cal-links:before, .single-tribe_events .content .nf-field-container.checkbox-container.label-left .input.-checkbox + .-label.-checkbox.tribe-events-cal-links:before {
  left: auto;
  right: 0; }

.nf-field-container.label-hidden .text.-label, .nf-field-container.label-hidden .single-tribe_events .content .-label.tribe-events-cal-links, .single-tribe_events .content .nf-field-container.label-hidden .-label.tribe-events-cal-links {
  position: absolute;
  left: -999em;
  top: -999em; }

.nf-field-container.listcheckbox-container.label-hidden .text.-label.-checkbox, .nf-field-container.listcheckbox-container.label-hidden .single-tribe_events .content .-label.-checkbox.tribe-events-cal-links, .single-tribe_events .content .nf-field-container.listcheckbox-container.label-hidden .-label.-checkbox.tribe-events-cal-links {
  left: auto;
  position: static;
  right: auto; }

/* ------------------------------------------------------------------------ * * Notificaiton Banners
\* ------------------------------------------------------------------------ */
.notification-banner-wrapper {
  background: #9F0000; }
  .notification-banner-wrapper.-alt {
    background: #98BE50; }
  .notification-banner-wrapper.-alt2 {
    background: #ADD95B; }

.notification-banner {
  margin: 0 auto;
  max-width: 1400px;
  max-width: 87.5rem;
  min-height: 5em;
  position: relative; }

.notification-title {
  align-items: center;
  border-bottom: 0.125rem solid rgba(0, 0, 0, 0.1);
  color: #FFFFFF;
  display: flex;
  font-family: "museo-slab", "Museo Slab", "Times New Roman", "Times", serif;
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1em;
  margin: 0;
  padding: 1em 4.33333em 1em 3.58333em;
  position: relative;
  text-transform: uppercase; }
  .notification-title .fa {
    color: #F83636;
    font-size: 1.75em;
    left: 0.71429em;
    position: absolute;
    transform: translateY(-50%);
    top: 50%; }
  .notification-banner-wrapper.-alt .notification-title .fa {
    color: #8ab242; }
  .notification-banner-wrapper.-alt2 .notification-title .fa {
    color: #7d8966; }

.notification-excerpt {
  color: #FFFFFF;
  flex: 1 1 auto;
  font-family: "museo-slab", "Museo Slab", "Times New Roman", "Times", serif;
  font-size: 0.9375em;
  line-height: 1.6em;
  margin: 0;
  padding: 1.6em 2em; }
  .notification-excerpt a {
    color: #F83636;
    text-decoration: none; }
  .notification-banner-wrapper.-alt .notification-excerpt a {
    color: #8ab242; }
  .notification-banner-wrapper.-alt2 .notification-excerpt a {
    color: #7d8966; }

.notification-content {
  padding: 1.6em 2em; }
  .notification-content.user-content ol,
  .notification-content.user-content p,
  .notification-content.user-content table,
  .notification-content.user-content ul {
    color: #FFFFFF;
    font-family: "museo-slab", "Museo Slab", "Times New Roman", "Times", serif;
    font-size: 0.9375em;
    line-height: 1.6em; }
  .notification-content.user-content > *:last-child {
    margin-bottom: 0; }
  .notification-content.user-content a {
    color: #F83636;
    text-decoration: none; }
  .notification-banner-wrapper.-alt .notification-content.user-content a {
    color: #8ab242; }
  .notification-banner-wrapper.-alt .notification-content.user-content a {
    color: #7d8966; }

.notification-button {
  background: none;
  border: 0;
  font-size: 1.5em;
  height: 4em;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 4.33333em; }
  .notification-button .fa {
    color: #F83636;
    font-size: 1.75em;
    left: 50%;
    position: absolute;
    transform: translateX(-50%) translateY(-50%);
    top: 50%; }
  .notification-banner-wrapper.-alt .notification-button .fa {
    color: #8ab242; }
  .notification-banner-wrapper.-alt2 .notification-button .fa {
    color: #7d8966; }

/* ------------------------------------------------------------------------ * * Pagination
\* ------------------------------------------------------------------------ */
.pagination-block {
  *zoom: 1; }
  .pagination-block:before, .pagination-block:after {
    content: "";
    display: table; }
  .pagination-block:after {
    clear: both; }

.pagination-block .pagination {
  *zoom: 1;
  margin: 1.25em 0em 0em; }
  .pagination-block .pagination:before, .pagination-block .pagination:after {
    content: "";
    display: table; }
  .pagination-block .pagination:after {
    clear: both; }

.pagination-block .pagination-prev {
  float: left;
  padding-right: 0.625em;
  max-width: 50%;
  text-align: left; }

.pagination-block .pagination-next {
  float: right;
  padding-left: 0.625em;
  max-width: 50%;
  text-align: right; }

/* ------------------------------------------------------------------------ * * Post Header
\* ------------------------------------------------------------------------ */
.post-header {
  background: #FFFFFF;
  position: relative; }

.post-header-image {
  margin: 0; }
  .post-header-image > img {
    display: block;
    height: auto !important;
    width: 100%; }

.post-header-title-wrapper {
  overflow: hidden;
  padding: 1.25em 1.5625em 0em; }

.post-header-title {
  color: #98BE50;
  font-family: "museo-slab", "Museo Slab", "Times New Roman", "Times", serif;
  font-size: 1.375em;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  text-transform: uppercase; }

.post-header-title.-alt {
  font-size: 1em; }

.post-header-subtitle {
  color: #98BE50;
  font-family: "museo-slab", "Museo Slab", "Times New Roman", "Times", serif;
  font-size: 1.25em;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  text-transform: uppercase; }

/* ------------------------------------------------------------------------ * * Post Footer
\* ------------------------------------------------------------------------ */
.post-footer {
  background: #7c9f3b;
  padding: 1.125em 1.25em; }

.post-footer-title {
  color: #FFFFFF;
  font-family: "museo-slab", "Museo Slab", "Times New Roman", "Times", serif;
  font-size: 1.375em;
  font-weight: 700;
  margin: 0;
  text-transform: uppercase; }

.post-footer .user-content h1,
.post-footer .user-content h2,
.post-footer .user-content h3,
.post-footer .user-content h4,
.post-footer .user-content h5,
.post-footer .user-content h6,
.post-footer .user-content ol,
.post-footer .user-content p,
.post-footer .user-content table,
.post-footer .user-content ul {
  color: #FFFFFF; }

.post-footer .user-content a {
  color: #cccfc6; }

.post-footer .user-content a:hover {
  color: #e5e6e2; }

.post-footer [class^="col"] {
  padding-top: 1.25em; }

/* ------------------------------------------------------------------------ * * Search Form
\* ------------------------------------------------------------------------ */
.search-form {
  background: #E9E9E9;
  display: flex;
  font-size: 1em;
  margin: 0em 0em 1em;
  width: 100%; }
  .search-form.-large {
    font-size: 1.5em; }
  .search-form-wrapper.-expandable .search-form {
    margin: 0;
    opacity: 0;
    overflow: hidden;
    position: relative;
    transition: opacity 0.35s, width 0.35s;
    width: 2.8125em;
    z-index: 1; }
  .search-form-wrapper.-expandable.-active .search-form {
    opacity: 1;
    width: 11.25em;
    z-index: 2; }
  .search-form ::-webkit-input-placeholder {
    color: #343434;
    opacity: 1; }
  .search-form :-moz-placeholder {
    color: #343434;
    opacity: 1; }
  .search-form ::-moz-placeholder {
    color: #343434;
    opacity: 1; }
  .search-form :-ms-input-placeholder {
    color: #343434;
    opacity: 1; }
  .search-form .placeholder {
    color: #343434;
    opacity: 1; }

.search-form-input {
  background: none;
  border: 0;
  color: #343434;
  flex: 1 1 auto;
  font-family: "museo-slab", "Museo Slab", "Times New Roman", "Times", serif;
  font-size: 1em;
  font-weight: 300;
  line-height: normal;
  margin: 0;
  padding: 0.5em 0.75em;
  text-overflow: ellipsis;
  width: 0; }
  .search-form-wrapper.-expandable .search-form-input {
    padding-bottom: 0.6875em;
    padding-top: 0.75em; }

.search-form-submit {
  background: none;
  border: 0;
  color: #000000;
  flex: none;
  font-size: 1.0625em;
  margin: 0;
  padding: 0.23529em 0.82353em 0.29412em;
  transition: color 0.15s; }
  .search-form-submit:hover {
    color: #0d0d0d; }
  .search-form-wrapper.-expandable .search-form-submit {
    font-size: 1.125em;
    padding: 0.66667em 0.77778em 0.5em; }

.search-form-toggle {
  background: none;
  border: 0;
  color: #000000;
  display: block;
  font-size: 1em;
  min-width: 2.8125em;
  padding: 0.8125em 0.75em 0.5625em;
  position: absolute;
  right: 0;
  top: 0;
  transition: color 0.15s, opacity 0.35s;
  z-index: 2; }
  .search-form-toggle:hover {
    color: #0d0d0d; }
  .search-form-wrapper.-expandable.-active .search-form-toggle {
    opacity: 0;
    z-index: 1; }
  .search-form-toggle .fa {
    font-size: 1.125em; }

/* ------------------------------------------------------------------------ * * Swiper
\* ------------------------------------------------------------------------ */
/**
 * Swiper 3.3.1
 * Most modern mobile touch slider and framework with hardware accelerated transitions
 *
 * http://www.idangero.us/swiper/
 *
 * Copyright 2016, Vladimir Kharlampidi
 * The iDangero.us
 * http://www.idangero.us/
 *
 * Licensed under MIT
 *
 * Released on: February 7, 2016
 */
.swiper-container {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  /* Fix of Webkit flickering */
  z-index: 1; }

.swiper-container-no-flexbox .swiper-slide {
  float: left; }

.swiper-container-vertical > .swiper-wrapper {
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column; }

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

.swiper-container-android .swiper-slide,
.swiper-wrapper {
  -webkit-transform: translate3d(0px, 0, 0);
  -moz-transform: translate3d(0px, 0, 0);
  -o-transform: translate(0px, 0px);
  -ms-transform: translate3d(0px, 0, 0);
  transform: translate3d(0px, 0, 0); }

.swiper-container-multirow > .swiper-wrapper {
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap; }

.swiper-container-free-mode > .swiper-wrapper {
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -ms-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  margin: 0 auto; }

.swiper-slide {
  -webkit-flex-shrink: 0;
  -ms-flex: 0 0 auto;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative; }

/* Auto Height */
.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
  height: auto; }

.swiper-container-autoheight .swiper-wrapper {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-transition-property: -webkit-transform, height;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform, height; }

/* a11y */
.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000; }

/* IE10 Windows Phone 8 Fixes */
.swiper-wp8-horizontal {
  -ms-touch-action: pan-y;
  touch-action: pan-y; }

.swiper-wp8-vertical {
  -ms-touch-action: pan-x;
  touch-action: pan-x; }

/* Arrows */
.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  -moz-background-size: 27px 44px;
  -webkit-background-size: 27px 44px;
  background-size: 27px 44px;
  background-position: center;
  background-repeat: no-repeat; }

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none; }

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  left: 10px;
  right: auto; }

.swiper-button-prev.swiper-button-black,
.swiper-container-rtl .swiper-button-next.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-prev.swiper-button-white,
.swiper-container-rtl .swiper-button-next.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  right: 10px;
  left: auto; }

.swiper-button-next.swiper-button-black,
.swiper-container-rtl .swiper-button-prev.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-next.swiper-button-white,
.swiper-container-rtl .swiper-button-prev.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E"); }

/* Pagination Styles */
.swiper-pagination {
  position: absolute;
  text-align: center;
  -webkit-transition: 300ms;
  -moz-transition: 300ms;
  -o-transition: 300ms;
  transition: 300ms;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 10; }

.swiper-pagination.swiper-pagination-hidden {
  opacity: 0; }

/* Common Styles */
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 10px;
  left: 0;
  width: 100%; }

/* Bullets */
.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background: #000;
  opacity: 0.2; }

button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  appearance: none; }

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer; }

.swiper-pagination-white .swiper-pagination-bullet {
  background: #fff; }

.swiper-pagination-bullet-active {
  opacity: 1;
  background: #007aff; }

.swiper-pagination-white .swiper-pagination-bullet-active {
  background: #fff; }

.swiper-pagination-black .swiper-pagination-bullet-active {
  background: #000; }

.swiper-container-vertical > .swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  -webkit-transform: translate3d(0px, -50%, 0);
  -moz-transform: translate3d(0px, -50%, 0);
  -o-transform: translate(0px, -50%);
  -ms-transform: translate3d(0px, -50%, 0);
  transform: translate3d(0px, -50%, 0); }

.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 5px 0;
  display: block; }

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 5px; }

/* Progress */
.swiper-pagination-progress {
  background: rgba(0, 0, 0, 0.25);
  position: absolute; }

.swiper-pagination-progress .swiper-pagination-progressbar {
  background: #007aff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: left top;
  -moz-transform-origin: left top;
  -ms-transform-origin: left top;
  -o-transform-origin: left top;
  transform-origin: left top; }

.swiper-container-rtl .swiper-pagination-progress .swiper-pagination-progressbar {
  -webkit-transform-origin: right top;
  -moz-transform-origin: right top;
  -ms-transform-origin: right top;
  -o-transform-origin: right top;
  transform-origin: right top; }

.swiper-container-horizontal > .swiper-pagination-progress {
  width: 100%;
  height: 4px;
  left: 0;
  top: 0; }

.swiper-container-vertical > .swiper-pagination-progress {
  width: 4px;
  height: 100%;
  left: 0;
  top: 0; }

.swiper-pagination-progress.swiper-pagination-white {
  background: rgba(255, 255, 255, 0.5); }

.swiper-pagination-progress.swiper-pagination-white .swiper-pagination-progressbar {
  background: #fff; }

.swiper-pagination-progress.swiper-pagination-black .swiper-pagination-progressbar {
  background: #000; }

/* 3D Container */
.swiper-container-3d {
  -webkit-perspective: 1200px;
  -moz-perspective: 1200px;
  -o-perspective: 1200px;
  perspective: 1200px; }

.swiper-container-3d .swiper-wrapper,
.swiper-container-3d .swiper-slide,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-cube-shadow {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d; }

.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10; }

.swiper-container-3d .swiper-slide-shadow-left {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.5)), to(transparent));
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(right, rgba(0, 0, 0, 0.5), transparent);
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(right, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(right, rgba(0, 0, 0, 0.5), transparent);
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 16+, IE10, Opera 12.50+ */ }

.swiper-container-3d .swiper-slide-shadow-right {
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(0, 0, 0, 0.5)), to(transparent));
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5), transparent);
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(left, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5), transparent);
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 16+, IE10, Opera 12.50+ */ }

.swiper-container-3d .swiper-slide-shadow-top {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(transparent));
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.5), transparent);
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(bottom, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.5), transparent);
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 16+, IE10, Opera 12.50+ */ }

.swiper-container-3d .swiper-slide-shadow-bottom {
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.5)), to(transparent));
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5), transparent);
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.5), transparent);
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 16+, IE10, Opera 12.50+ */ }

/* Coverflow */
.swiper-container-coverflow .swiper-wrapper,
.swiper-container-flip .swiper-wrapper {
  /* Windows 8 IE 10 fix */
  -ms-perspective: 1200px; }

/* Cube + Flip */
.swiper-container-cube,
.swiper-container-flip {
  overflow: visible; }

.swiper-container-cube .swiper-slide,
.swiper-container-flip .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1; }

.swiper-container-cube .swiper-slide .swiper-slide,
.swiper-container-flip .swiper-slide .swiper-slide {
  pointer-events: none; }

.swiper-container-cube .swiper-slide-active,
.swiper-container-flip .swiper-slide-active,
.swiper-container-cube .swiper-slide-active .swiper-slide-active,
.swiper-container-flip .swiper-slide-active .swiper-slide-active {
  pointer-events: auto; }

.swiper-container-cube .swiper-slide-shadow-top,
.swiper-container-flip .swiper-slide-shadow-top,
.swiper-container-cube .swiper-slide-shadow-bottom,
.swiper-container-flip .swiper-slide-shadow-bottom,
.swiper-container-cube .swiper-slide-shadow-left,
.swiper-container-flip .swiper-slide-shadow-left,
.swiper-container-cube .swiper-slide-shadow-right,
.swiper-container-flip .swiper-slide-shadow-right {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden; }

/* Cube */
.swiper-container-cube .swiper-slide {
  visibility: hidden;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  width: 100%;
  height: 100%; }

.swiper-container-cube.swiper-container-rtl .swiper-slide {
  -webkit-transform-origin: 100% 0;
  -moz-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  transform-origin: 100% 0; }

.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-next,
.swiper-container-cube .swiper-slide-prev,
.swiper-container-cube .swiper-slide-next + .swiper-slide {
  pointer-events: auto;
  visibility: visible; }

.swiper-container-cube .swiper-cube-shadow {
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.6;
  -webkit-filter: blur(50px);
  filter: blur(50px);
  z-index: 0; }

/* Fade */
.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -ms-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out; }

.swiper-container-fade .swiper-slide {
  pointer-events: none;
  -webkit-transition-property: opacity;
  -moz-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity; }

.swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none; }

.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto; }

/* Scrollbar */
.swiper-scrollbar {
  border-radius: 10px;
  position: relative;
  -ms-touch-action: none;
  background: rgba(0, 0, 0, 0.1); }

.swiper-container-horizontal > .swiper-scrollbar {
  position: absolute;
  left: 1%;
  bottom: 3px;
  z-index: 50;
  height: 5px;
  width: 98%; }

.swiper-container-vertical > .swiper-scrollbar {
  position: absolute;
  right: 3px;
  top: 1%;
  z-index: 50;
  width: 5px;
  height: 98%; }

.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  left: 0;
  top: 0; }

.swiper-scrollbar-cursor-drag {
  cursor: move; }

/* Preloader */
.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  -webkit-transform-origin: 50%;
  -moz-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  -moz-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  animation: swiper-preloader-spin 1s steps(12, end) infinite; }

.swiper-lazy-preloader:after {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-position: 50%;
  -webkit-background-size: 100%;
  background-size: 100%;
  background-repeat: no-repeat; }

.swiper-lazy-preloader-white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E"); }

@-webkit-keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes swiper-preloader-spin {
  100% {
    transform: rotate(360deg); } }

.swiper-container {
  background: #E9E9E9;
  display: flex; }

.swiper-slide {
  background: #FFFFFF;
  height: auto;
  margin: 0; }

.swiper-image {
  display: block;
  width: 100%; }

.swiper-caption {
  background: #FFFFFF;
  padding: 1.25em 1.5625em; }

.swiper-caption-title {
  color: #6f932a;
  font-family: "museo-slab", "Museo Slab", "Times New Roman", "Times", serif;
  font-size: 1.125em;
  font-weight: 700;
  margin: 0em 0em 0.1em;
  text-transform: uppercase; }
  .swiper-caption-title:last-child {
    margin-bottom: 0; }

.swiper-caption-content .user-content {
  /* general text */
  /* modifiers */
  /* special */ }
  .swiper-caption-content .user-content ol,
  .swiper-caption-content .user-content p,
  .swiper-caption-content .user-content table,
  .swiper-caption-content .user-content ul {
    font-size: 0.75em; }
  .swiper-caption-content .user-content a:not(.button):not(.tribe-events-button) {
    color: #000000;
    font-weight: 700; }
    .swiper-caption-content .user-content a:not(.button):not(.tribe-events-button):hover {
      color: #0d0d0d;
      text-decoration: underline; }
  .swiper-caption-content .user-content > *:last-child {
    margin-bottom: 0 !important; }

.swiper-menu {
  display: none; }

.no-csstransforms .swiper-slide:not(:first-child) {
  display: none; }

/* ------------------------------------------------------------------------ * * Tabs
\* ------------------------------------------------------------------------ */
.tab-wrapper {
  font-size: 1em;
  padding-top: 3.0625em;
  position: relative; }
  @media screen and (max-width: 47.9375rem) {
    .tab-wrapper.-accordion {
      padding-top: 0; } }

.tab-menu {
  border-bottom: 0.1875rem solid #98BE50;
  display: flex;
  left: 0;
  list-style: none;
  margin: 0;
  padding: 0em 0.6875em;
  position: absolute;
  right: 0;
  top: 0; }
  @media screen and (max-width: 47.9375rem) {
    .tab-wrapper.-accordion .tab-menu {
      display: none; } }

.tab-menu-item {
  flex: 1 1 auto;
  padding: 0em 0.6875em; }

.tab-menu-button {
  background: #FFFFFF;
  border: 1px solid #E9E9E9;
  border-bottom: 0;
  color: #000000;
  display: block;
  font-family: "museo-slab", "Museo Slab", "Times New Roman", "Times", serif;
  font-size: 1.125em;
  font-weight: 300;
  margin: 0;
  padding: 0.66667em 0.55556em 0.55556em;
  text-align: center;
  text-transform: uppercase;
  width: 100%; }
  .tab-menu-button.-active {
    background: #98BE50;
    border-color: #98BE50;
    color: #FFFFFF; }
  @media screen and (max-width: 47.9375rem) {
    .tab-wrapper.-accordion > .tab-menu-button {
      background: #98BE50;
      border-color: #98BE50;
      color: #FFFFFF;
      font-size: 1.5em;
      margin-bottom: 0.875em;
      padding: 0.5em 2.66667em 0.41667em 1em;
      position: relative;
      text-align: left; }
    .tab-wrapper.-accordion > .tab-menu-button .fa {
      font-weight: 300;
      position: absolute;
      right: 1em;
      top: 50%;
      transform: translateY(-50%); } }

@media screen and (max-width: 47.9375rem) {
  .tab-wrapper.-accordion > .tab-content {
    padding: 0em 1.5em; } }

.tab-content-footer {
  color: #98BE50;
  font-family: "museo-slab", "Museo Slab", "Times New Roman", "Times", serif;
  font-size: 1.05187em;
  font-weight: 700;
  margin: 1.6637em 0em 0em; }
  .tab-content-footer a {
    color: inherit;
    text-decoration: none;
    transition: color 0.15s; }
  .tab-content-footer:hover a {
    color: #ADD95B; }

/* ------------------------------------------------------------------------ * * Tribe Events
\* ------------------------------------------------------------------------ */
#tribe-events-bar #tribe-bar-form #tribe-bar-views .tribe-bar-views-inner .tribe-bar-views-list li a {
  text-decoration: none; }

#tribe-events-bar #tribe-bar-form #tribe-bar-views .tribe-bar-views-inner .tribe-bar-views-list li a span:before {
  top: 2px !important; }

#tribe-events-bar #tribe-bar-form #tribe-bar-views .tribe-bar-views-inner .tribe-bar-views-list li a span.tribe-icon-month:before {
  content: "" true;
  font-family: "fontawesome" true; }

#tribe-events-bar #tribe-bar-form #tribe-bar-views .tribe-bar-views-inner .tribe-bar-views-list li a span.tribe-icon-list:before {
  content: "" true;
  font-family: "fontawesome" true; }

#tribe-events-bar #tribe-bar-form #tribe-bar-views .tribe-bar-views-inner .tribe-bar-views-list li a span.tribe-icon-day:before {
  content: "" true;
  font-family: "fontawesome" true; }

#tribe-events-bar #tribe-bar-form .tribe-bar-filters .tribe-bar-filters-inner label {
  padding-right: 10px; }

.tribe-events-calendar {
  color: #000000;
  font-family: "museo-slab", "Museo Slab", "Times New Roman", "Times", serif;
  font-size: 1em;
  font-weight: 300;
  line-height: 1.5em;
  table-layout: fixed;
  width: 100%; }

.tribe-events-calendar td {
  border: 0.1875rem solid #E9E9E9;
  height: 3.75em;
  padding: 0.5em 0.625em;
  position: relative;
  text-align: center;
  vertical-align: top;
  width: 14.28571%; }

@media screen and (max-width: 47.9375rem) {
  .tribe-events-calendar td.mobile-active {
    background-color: #c4da9a; } }

.tribe-events-calendar td.tribe-events-othermonth {
  background: #f9f9f9; }

.tribe-events-calendar div[id*=tribe-events-daynum-],
.tribe-events-calendar div[id*=tribe-events-daynum-] a {
  text-align: right; }

.tribe-events-calendar div[id*=tribe-events-daynum-] a {
  color: #000000;
  text-decoration: none;
  transition: color 0.15s; }

.tribe-events-calendar div[id*=tribe-events-daynum-] a:hover {
  color: #1a1a1a; }

.tribe-events-calendar .tribe-events-month-event-title {
  display: none;
  font-size: 0.875em;
  font-weight: 400;
  line-height: normal;
  margin: 0; }

.tribe-events-calendar a {
  font-weight: 400 !important; }

.tribe-events-calendar .tribe-events-tooltip {
  background: #98BE50;
  bottom: 100% !important;
  left: 50%;
  padding: 1em 1.25em 0.8125em;
  position: absolute;
  transform: translateX(-50%);
  width: 200%; }

.tribe-events-calendar .tribe-events-tooltip::after {
  border-left: 0.625rem solid transparent;
  border-right: 0.625rem solid transparent;
  border-top: 0.625rem solid #98BE50;
  content: "\0020";
  display: block;
  height: 0;
  left: 50%;
  position: absolute;
  top: 100%;
  transform: translateX(-50%);
  width: 0; }

.tribe-events-calendar .tribe-events-tooltip * {
  color: #FFFFFF !important; }

.tribe-events-calendar .tribe-events-tooltip .entry-title {
  color: #FFFFFF;
  font-size: 1.125em;
  font-family: "museo-slab", "Museo Slab", "Times New Roman", "Times", serif;
  font-weight: 700;
  line-height: 1.22222em;
  margin: 0;
  text-transform: uppercase; }

.tribe-events-calendar .tribe-events-tooltip .tribe-events-event-body {
  font-size: 1em;
  padding: 0; }

.tribe-events-calendar .tribe-events-tooltip .tribe-event-duration {
  color: #FFFFFF;
  display: inline;
  font-family: "museo-slab", "Museo Slab", "Times New Roman", "Times", serif;
  font-size: 1em;
  font-weight: 300;
  line-height: 1.25em;
  margin: 0;
  text-transform: uppercase; }

.tribe-events-calendar .tribe-events-tooltip .tribe-events-event-body p {
  color: #FFFFFF;
  font-size: 0.875em;
  margin: 0; }

.tribe-events-calendar .tribe-events-tooltip .link, .tribe-events-calendar .tribe-events-tooltip .pagination-block a, .pagination-block .tribe-events-calendar .tribe-events-tooltip a, .tribe-events-calendar .tribe-events-tooltip .post-header-title a, .post-header-title .tribe-events-calendar .tribe-events-tooltip a, .tribe-events-calendar .tribe-events-tooltip .post-header-subtitle a, .post-header-subtitle .tribe-events-calendar .tribe-events-tooltip a {
  color: #FFFFFF; }

.tribe-events-list-separator-month {
  color: #98BE50 !important;
  display: block;
  font-family: "museo-slab", "Museo Slab", "Times New Roman", "Times", serif;
  font-size: 1.25em !important;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  margin: 1em 0em;
  position: relative; }

.tribe-events-list-separator-month:after {
  background: none !important;
  border-bottom: 0.1875rem solid #E9E9E9;
  content: "\0020";
  display: block;
  height: 0 !important;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  z-index: 1; }

.tribe-events-list-separator-month > span {
  background: #FFFFFF;
  display: inline-block;
  padding: 0em 1em;
  position: relative;
  z-index: 2; }

.tribe-events-list-separator-month + .article-list-item > .article-card {
  border-top: 0 !important; }

.post-type-archive-tribe_events .article-list-item:not(:last-of-type) > .article-card:not(.-box),
.post-type-archive-tribe_events :not([class^="col"]) > .article-card:not(.-box):not(:last-of-type),
.post-type-archive-tribe_events [class^="col"]:not(:last-of-type) > .article-card:not(.-box) {
  border-bottom: 0;
  padding-bottom: 0; }

.single-tribe_events .content .article-card-meta-list-item .address {
  text-align: left; }

.single-tribe_events .content .article-card-meta-list-item .address a {
  display: block; }

.single-tribe_events .content .tribe-events-cal-links {
  margin-top: 1em; }

.single-tribe_events .content .tribe-events-cal-links a {
  color: #FFFFFF;
  margin-right: 1.25em; }

/* ------------------------------------------------------------------------ * * Visually Hidden 
\* ------------------------------------------------------------------------ */
.visually-hidden {
  left: -999em;
  position: absolute;
  top: -999em; }

/* ------------------------------------------------------------------------ * * Weblinx
\* ------------------------------------------------------------------------ */
.weblinx {
  color: #E9E9E9;
  font-family: "museo-slab", "Museo Slab", "Times New Roman", "Times", serif;
  font-size: 0.675em;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  text-transform: uppercase; }

.weblinx a {
  color: #E9E9E9;
  text-decoration: none;
  transition: color 0.15s; }

.weblinx a:hover {
  color: #FFFFFF; }

/* ------------------------------------------------------------------------ * * Widget Card
\* ------------------------------------------------------------------------ */
.widget-card {
  *zoom: 1;
  background: #FFFFFF;
  box-shadow: 0em 0.0625em 0.26562em 0.04688em rgba(0, 0, 0, 0.2);
  position: relative; }
  .widget-card:before, .widget-card:after {
    content: "";
    display: table; }
  .widget-card:after {
    clear: both; }
  .widget-card:not(:last-child) {
    margin-bottom: 1.25em; }
  .widget-card.-alt {
    align-items: center;
    display: flex; }
  .widget-card.-tabbed {
    background: none;
    box-shadow: none;
    padding-top: 3.0625em; }

.widget-card a {
  color: inherit;
  text-decoration: none; }

.widget-card-image {
  flex: none;
  margin: 0;
  width: 9.375em; }
  .widget-card.-compact .widget-card-image {
    width: 3.125em; }

.widget-card-image img {
  display: block;
  height: auto !important;
  width: 100%; }

.widget-card-content {
  padding: 1.125em 1.25em;
  width: 100%; }
  .widget-card.-compact .widget-card-content {
    padding: 0; }
  .widget-card.-scroll .widget-card-content {
    max-height: 30.625em;
    overflow: auto; }
  .widget-card.-tabbed .widget-card-content {
    background: #FFFFFF;
    box-shadow: 0em 0.0625em 0.26562em 0.04688em rgba(0, 0, 0, 0.2); }
    .widget-card.-tabbed .widget-card-content .tab-content {
      padding: 0; }
  .widget-card-image + .widget-card-content {
    flex: 1 1 auto; }

.content-wrapper:not(.-map) .widget-card-content > .tab-content {
  font-size: 0.875em; }

.widget-card-title {
  color: #98BE50;
  font-family: "museo-slab", "Museo Slab", "Times New Roman", "Times", serif;
  font-size: 1.25em;
  font-weight: 700;
  line-height: normal;
  margin: 0em 0em 0.09091em;
  text-transform: uppercase; }
  .widget-card.-compact .widget-card-title {
    line-height: 1.09091em; }
  .widget-card-title:last-child {
    margin-bottom: 0; }
  .widget-card-title.-alt {
    font-size: 0.875em;
    text-transform: lowercase; }

.widget-card-title a {
  display: block; }
  .widget-card.-compact .widget-card-title a {
    padding: 0.45455em 1.36364em 0.22727em; }
  .widget-card.-compact .widget-card-title a .fa {
    margin-right: 0.40909em; }

.widget-card-excerpt {
  margin: 0; }

.widget-card .text, .widget-card .single-tribe_events .content .tribe-events-cal-links, .single-tribe_events .content .widget-card .tribe-events-cal-links {
  margin: 0em 0em 1em; }

.widget-card .text.-shortmargin, .widget-card .single-tribe_events .content .-shortmargin.tribe-events-cal-links, .single-tribe_events .content .widget-card .-shortmargin.tribe-events-cal-links {
  margin-bottom: 0.25em; }

.widget-card-button {
  color: #98BE50;
  font-family: "museo-slab", "Museo Slab", "Times New Roman", "Times", serif;
  font-size: 1.25em;
  font-weight: 700;
  line-height: normal;
  margin: 0em 0em 0.09091em;
  text-transform: uppercase; }

/* alt2 variant */
.widget-card.-alt2 {
  background: #98BE50; }

/* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Page
\* ------------------------------------------------------------------------ */
html,
body {
  width: 100%; }

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility; }

body {
  background: #FFFFFF; }

html.-nav-active,
.-nav-active body {
  height: 100%;
  overflow: hidden;
  width: 100%; }

.page-wrapper {
  background: #FFFFFF;
  font-size: 16px;
  font-size: 1rem;
  left: 0;
  min-height: 100%;
  position: relative;
  transition: box-shadow 0.15s, left 0.15s;
  transform: translateZ(0);
  width: 100%;
  z-index: 2; }
  .-nav-active .page-wrapper {
    box-shadow: 0em 0em 0.875em 0.14875em rgba(0, 0, 0, 0.35);
    height: 100%;
    left: calc(-100% + 4.21875em);
    overflow: hidden;
    width: 100%; }
  .page-wrapper:before {
    background: rgba(0, 0, 0, 0.5);
    bottom: 100%;
    content: "\0020";
    display: block;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 100%;
    top: 0;
    transition: bottom 0s 0.15s, opacity 0.15s, right 0s 0.15s;
    transform: translateZ(0);
    z-index: 998; }
  .-nav-active .page-wrapper:before {
    bottom: 0;
    opacity: 1;
    right: 0;
    transition: opacity 0.15s; }

@-ms-viewport {
  width: device-width; }

/* ------------------------------------------------------------------------ * * Toolbar
\* ------------------------------------------------------------------------ */
.toolbar-wrapper {
  position: relative;
  z-index: 6;
  background: #686868;
  font-size: 1em;
  padding: 0.9375em 1.5625em; }

.toolbar {
  *zoom: 1;
  display: flex;
  margin: 0 auto;
  max-width: 87.5em; }
  .toolbar:before, .toolbar:after {
    content: "";
    display: table; }
  .toolbar:after {
    clear: both; }

.toolbar .search-form {
  border-radius: 1.09375em;
  flex: 1 1 0%;
  margin: 0; }

.toolbar .menu-button {
  flex: none;
  transform: translateX(0.625em); }

.toolbar .menu-wrapper.-social .menu-list {
  color: #FFFFFF; }

.toolbar .menu-wrapper.-social .menu-item {
  border-left: 0.03333em solid #828282; }

.toolbar .menu-wrapper.-social .menu-link {
  color: #FFFFFF;
  padding: 0.8125em 0.75em 0.5625em; }
  .toolbar .menu-wrapper.-social .menu-link:hover {
    color: #E9E9E9; }
  .toolbar .menu-wrapper.-social .menu-link .fa {
    background: none;
    border: 0;
    color: inherit;
    font-size: 1.125em;
    height: auto;
    line-height: inherit;
    width: auto; }

.toolbar .menu-wrapper.-social .search-form {
  background: #FFFFFF; }

.toolbar .menu-wrapper.-social .search-form-submit {
  color: #000000; }
  .toolbar .menu-wrapper.-social .search-form-submit:hover {
    color: #343434; }

.toolbar .menu-wrapper.-social .search-form-toggle {
  color: #FFFFFF; }
  .toolbar .menu-wrapper.-social .search-form-toggle:hover {
    color: #E9E9E9; }

/* ------------------------------------------------------------------------ * * Header
\* ------------------------------------------------------------------------ */
.header-wrapper {
  position: relative;
  z-index: 5;
  background: #141414;
  font-size: 1em;
  padding: 1.25em; }
  .-nav-active .header-wrapper {
    position: static; }

.header {
  *zoom: 1;
  margin: 0 auto;
  max-width: 87.5em; }
  .header:before, .header:after {
    content: "";
    display: table; }
  .header:after {
    clear: both; }

.header .logo {
  margin: 0 auto;
  max-width: 17.5em;
  padding: 0.3125em;
  width: 100%; }

.header .menu-wrapper {
  float: right; }
  .header .menu-wrapper.-nav {
    background: none; }

/* ------------------------------------------------------------------------ * * Navigation
\* ------------------------------------------------------------------------ */
.nav-wrapper {
  position: relative;
  z-index: 4;
  font-size: 16px;
  font-size: 1rem;
  padding: 0 20px;
  padding: 0rem 1.25rem; }

.nav {
  *zoom: 1;
  margin: 0 auto;
  max-width: 1400px;
  max-width: 87.5rem; }
  .nav:before, .nav:after {
    content: "";
    display: table; }
  .nav:after {
    clear: both; }

/* ------------------------------------------------------------------------ * * Mobile Navigation
\* ------------------------------------------------------------------------ */
.mobile-nav-wrapper {
  bottom: 0;
  display: block;
  font-size: 16px;
  font-size: 1rem;
  left: -999em;
  overflow: auto;
  padding: 0em 0em 0em 4.21875em;
  position: absolute;
  right: 999em;
  top: -999em;
  transition: left 0s 0.15s, right 0s 0.15s, top 0s 0.15s;
  z-index: 1; }
  .-nav-active .mobile-nav-wrapper {
    left: 0;
    right: 0;
    transition: none;
    top: 0; }
  .-nav-active .admin-bar .mobile-nav-wrapper {
    top: 46px; }
  .mobile-nav-wrapper.-no-overflow {
    height: 100%;
    overflow: hidden; }

.mobile-nav {
  min-height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%; }
  .mobile-nav-wrapper.-no-overflow .mobile-nav {
    height: 100%; }

.mobile-nav .menu-wrapper.-social .menu-list {
  color: #ADD95B; }

.mobile-nav .menu-wrapper.-social .menu-item:not(:last-child) {
  border-right: 0.03333em solid #ADD95B; }

.mobile-nav .menu-wrapper.-social .menu-link {
  color: #ADD95B;
  padding: 0.8125em 0.75em 0.5625em; }
  .mobile-nav .menu-wrapper.-social .menu-link:hover {
    color: #c2e384; }
  .mobile-nav .menu-wrapper.-social .menu-link .fa {
    background: none;
    border: 0;
    color: inherit;
    font-size: 1.125em;
    height: auto;
    line-height: inherit;
    width: auto; }

.mobile-nav .button, .mobile-nav .tribe-events-button {
  box-shadow: none; }

/* ------------------------------------------------------------------------ * * Slideshow
\* ------------------------------------------------------------------------ */
.slideshow-wrapper {
  position: relative;
  z-index: 3;
  background: url("../media/background-content.jpg") center top no-repeat #3d3d3d;
  font-size: 1em; }

.slideshow {
  *zoom: 1;
  margin: 0 auto;
  max-width: 87.5rem; }
  .slideshow:before, .slideshow:after {
    content: "";
    display: table; }
  .slideshow:after {
    clear: both; }

/* ------------------------------------------------------------------------ * * Content
\* ------------------------------------------------------------------------ */
.content-wrapper {
  position: relative;
  z-index: 2;
  background: url("../media/background-content.jpg") center top no-repeat #3d3d3d;
  font-size: 16px;
  font-size: 1rem; }
  .home .content-wrapper {
    background: #FFFFFF; }

.content {
  *zoom: 1;
  background: #FFFFFF;
  border-top: 0.5rem solid #98BE50;
  margin: 0 auto;
  max-width: 1400px;
  max-width: 87.5rem; }
  .content:before, .content:after {
    content: "";
    display: table; }
  .content:after {
    clear: both; }
  .home .content {
    border-top: 0;
    padding: 1.25em 1.5625em; }

.content-post {
  *zoom: 1;
  padding: 1.25em 1.5625em; }
  .content-post:before, .content-post:after {
    content: "";
    display: table; }
  .content-post:after {
    clear: both; }
  .home .content-post {
    padding-left: 0;
    padding-right: 0; }
  .post-header.-noimage + .content-post {
    padding-top: 0; }

.content-post .swiper-container {
  margin: 0em 0em 1.375em; }

.content-sidebar {
  *zoom: 1;
  padding: 0em 1.5625em 1.25em; }
  .content-sidebar:before, .content-sidebar:after {
    content: "";
    display: table; }
  .content-sidebar:after {
    clear: both; }
  .home .content-sidebar {
    padding: 0; }

.content-sidebar .widget-card:not(:last-child):not(.-alt):not(.-alt2) {
  margin-bottom: 3.75em; }

.content .article-list.-related {
  padding-top: 2.5em; }

.content .-events:not(:last-child) {
  padding-bottom: 1.875em; }

.content-wrapper.-alt {
  background: url("../media/background-content-alt.png") center top/100% 100% no-repeat #1c1c1c; }
  .content-wrapper.-alt .content {
    background: none; }

/* ------------------------------------------------------------------------ * * Footer
\* ------------------------------------------------------------------------ */
.footer-wrapper {
  position: relative;
  z-index: 1;
  background: url("../media/background-footer.jpg") center top no-repeat #686868;
  font-size: 1em;
  padding: 2.1875em 1.25em; }

.footer {
  *zoom: 1;
  margin: 0 auto;
  max-width: 87.5em; }
  .footer:before, .footer:after {
    content: "";
    display: table; }
  .footer:after {
    clear: both; }

.footer_row .col,
.footer_row [class^="col-"] {
  overflow: hidden; }

.footer_title {
  color: #FFFFFF;
  font-family: "museo-slab", "Museo Slab", "Times New Roman", "Times", serif;
  font-size: 1.05469em;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0em 0em 0.08889em; }

.footer_text {
  color: #FFFFFF;
  font-size: 0.675em; }

.footer_logo {
  float: left;
  margin: 0em 1.40625em 1.875em 0em;
  width: 6.25em; }

.footer_logo.-alt {
  width: 4.6875em; }

.footer_address .address_title {
  color: #FFFFFF; }

.footer_address .address_text {
  color: #FFFFFF; }

.footer_copyright,
.footer_weblinx {
  clear: both;
  text-align: center; }

@media screen and (min-width: 30rem) {
  /* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------ * * Hiders
\* ------------------------------------------------------------------------ */
  .hide-xxs {
    display: none !important; }
  .show-xxs {
    display: block !important; }
  /* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */ }

@media screen and (min-width: 40rem) {
  /* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------ * * Align
\* ------------------------------------------------------------------------ */
  .aligncenter {
    margin: 0 auto 1.875em; }
  .alignleft {
    float: left;
    margin: 0em 1.875em 1.875em 0em; }
  .alignright {
    float: right;
    margin: 0em 0em 1.875em 1.875em; }
  /* ------------------------------------------------------------------------ * * Hiders
\* ------------------------------------------------------------------------ */
  .hide-xs {
    display: none !important; }
  .show-xs {
    display: block !important; }
  /* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */ }

@media screen and (min-width: 48rem) {
  /* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------ * * Article Card
\* ------------------------------------------------------------------------ */
  .article-list-item:not(:first-of-type) > .article-card:not(.-box),
  :not([class^="col"]) > .article-card:not(.-box):not(:first-of-type),
  [class^="col"]:not(:first-of-type) > .article-card:not(.-box) {
    border-top: 0;
    padding-top: 0; }
  .row:not(:last-of-type) > [class^="col"] > .article-card:not(.-box) {
    padding-bottom: 1.375em; }
  .article-card.-box {
    margin-bottom: 0;
    max-width: none; }
  .article-card:not(.-box):not(.-vertical) .article-card-image {
    float: left;
    padding-right: 1.5625em;
    width: 33.33333%; }
  .article-card:not(.-box):not(.-vertical) .article-card-image + .article-card-content {
    float: left;
    width: 66.66667%; }
  .article-card.-box .article-card-content {
    padding-bottom: 1.625em;
    padding-top: 1.625em; }
  .article-card-title {
    font-size: 1.375em;
    margin: 0em 0em 0.45455em; }
  .article-card.-box.-alt .article-card-title {
    margin-bottom: 0; }
  .article-card.-box:not(.-alt) > .article-card-image + .article-card-content > .article-card-date {
    font-size: 0.875em; }
  .article-card.-box.-alt .article-card-date {
    margin-bottom: 0.66667em; }
  .article-card-excerpt {
    font-size: 1.125em;
    line-height: 1.66667em; }
  .article-card.-box .article-card-excerpt {
    font-size: 0.9375em;
    line-height: 1.5em; }
  .article-card-meta-group {
    float: left;
    margin-bottom: 1.25em;
    width: 50%; }
  .article-card-meta-group:nth-last-child(2) {
    clear: left;
    padding-right: 1.25em; }
  .article-card-meta-group:nth-last-child(2) + .article-card-meta-group {
    padding-left: 1.25em; }
  /* ------------------------------------------------------------------------ * * Article List
\* ------------------------------------------------------------------------ */
  .article-list-header.-box {
    margin: 0;
    max-width: none; }
  .article-list-header .heading.-sub {
    float: left;
    margin: 0em 0em 1.07143em; }
  .article-list-header .heading.-alt.-small {
    display: block;
    float: right;
    margin: 0.5em 0em 2.125em; }
  .article-list-footer.-box {
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    max-width: none; }
  /* ------------------------------------------------------------------------ * * Button
\* ------------------------------------------------------------------------ */
  .button.-big, .-big.tribe-events-button {
    font-size: 1.25em;
    padding: 0.525em 1.1825em 0.4em; }
  /* ------------------------------------------------------------------------ * * Callouts
\* ------------------------------------------------------------------------ */
  .callouts {
    display: flex;
    font-size: 0.875em; }
  .callouts-post {
    display: flex;
    flex: 1 1 auto;
    margin: 0em 1.25em 0em 0em;
    width: 66.66667%; }
  .callouts-post .article-list {
    display: flex;
    flex-direction: column;
    width: 100%; }
  .callouts-post .article-list-header {
    flex: none; }
  .callouts-post .article-list-content {
    display: flex;
    flex: 1 1 auto;
    width: 100%; }
  .callouts-post .article-list-content .article-card,
  .callouts-post .article-list-content .article-card.-box {
    flex: 1 1 auto;
    margin: 0;
    width: 0; }
  .callouts-post .article-list-content .article-card:first-child,
  .callouts-post .article-list-content .article-card.-box:first-child {
    margin-right: 1.25em; }
  .callouts-post .article-list-content .article-card:last-child,
  .callouts-post .article-list-content .article-card.-box:last-child {
    display: none; }
  .callouts-sidebar {
    flex: 1 1 auto;
    width: 33.33333%; }
  /* ------------------------------------------------------------------------ * * Hiders
\* ------------------------------------------------------------------------ */
  .hide-s {
    display: none !important; }
  .show-s {
    display: block !important; }
  /* ------------------------------------------------------------------------ * * Notificaiton Banners
\* ------------------------------------------------------------------------ */
  .notification-banner-wrapper {
    padding: 0em 1.25em; }
  .notification-banner {
    display: flex;
    min-height: 5.375em; }
  .notification-title {
    border-bottom: 0;
    border-right: 0.125rem solid rgba(0, 0, 0, 0.1);
    flex: none;
    padding: 1em 2.33333em; }
    .notification-title .fa {
      left: 0; }
  .notification-excerpt,
  .notification-content {
    flex: 1 1 auto; }
    .notification-excerpt:not(:last-child),
    .notification-content:not(:last-child) {
      padding-right: 12.5em; }
  .notification-button {
    height: 100%;
    min-height: 3.58333em;
    width: 3.58333em; }
  /* ------------------------------------------------------------------------ * * Post Header
\* ------------------------------------------------------------------------ */
  .post-header-title-wrapper {
    padding: 2.1875em 2.5em 0em; }
    .post-header:not(.-noimage) .post-header-title-wrapper {
      background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.1) 25%, rgba(0, 0, 0, 0.2) 50%, rgba(0, 0, 0, 0.25) 75%, rgba(0, 0, 0, 0.25) 100%);
      bottom: 0;
      left: 0;
      padding-bottom: 0.625em;
      position: absolute;
      right: 0; }
  .post-header-title {
    font-size: 2.625em; }
    .post-header:not(.-noimage) .post-header-title-wrapper .post-header-title {
      color: #FFFFFF;
      text-shadow: 0em 0.04444em 0.09444em rgba(0, 0, 0, 0.2); }
  .post-header-title.-alt {
    font-size: 1.375em; }
  .post-header-subtitle {
    font-size: 2.25em; }
    .post-header:not(.-noimage) .post-header-title-wrapper .post-header-subtitle {
      color: #FFFFFF;
      text-shadow: 0em 0.05556em 0.11806em rgba(0, 0, 0, 0.2); }
  /* ------------------------------------------------------------------------ * * Post Footer
\* ------------------------------------------------------------------------ */
  .post-footer {
    margin-top: 2.5em;
    padding: 1.75em 1.875em; }
  .post-footer .user-content ol,
  .post-footer .user-content p,
  .post-footer .user-content table,
  .post-footer .user-content ul {
    font-size: 0.9375em; }
  .post-footer [class^="col"] {
    width: 0; }
    .post-footer [class^="col"]:not(:last-child) {
      padding-right: 1.25em; }
  /* ------------------------------------------------------------------------ * * Swiper
\* ------------------------------------------------------------------------ */
  .swiper-wrapper {
    position: relative;
    width: 100%;
    z-index: 1; }
  .swiper-caption {
    background: rgba(255, 255, 255, 0.75);
    bottom: 0;
    left: 0;
    padding: 1.25em 1.5625em;
    position: absolute;
    right: 0; }
  .swiper-caption-title {
    font-size: 1.625em; }
  .swiper-caption-content .user-content {
    /* general text */ }
    .swiper-caption-content .user-content ol,
    .swiper-caption-content .user-content p,
    .swiper-caption-content .user-content table,
    .swiper-caption-content .user-content ul {
      font-size: 1em; }
  .swiper-menu {
    background: #FFFFFF;
    bottom: 0;
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 32.85714285714286%;
    z-index: 2; }
  .swiper-menu-item {
    display: flex;
    flex: 1 1 auto;
    height: 0; }
  .swiper-menu-button {
    background: none;
    border: 0;
    display: block;
    color: #98BE50;
    flex: 1 1 auto;
    font-family: "museo-slab", "Museo Slab", "Times New Roman", "Times", serif;
    font-size: 1.25em;
    font-weight: 700;
    line-height: 1.18182em;
    margin: 0;
    padding: 0.90909em 1.36364em;
    position: relative;
    text-align: left;
    text-transform: uppercase;
    transition: background 0.15s, color 0.15s;
    width: 100%; }
    .swiper-menu-item.-active .swiper-menu-button {
      background: #98BE50;
      color: #000000; }
    .swiper-menu-button:before {
      background: url("../media/background-triangle.svg") left center/2.7em 100% no-repeat;
      bottom: 0;
      content: "\0020";
      display: block;
      height: 100%;
      left: -999em;
      opacity: 0;
      position: absolute;
      transition: left 0s 0.15s, opacity 0.15s;
      top: 0;
      width: 2.7em; }
    .swiper-menu-item.-active .swiper-menu-button:before {
      opacity: 1;
      left: -2.2em;
      transition: opacity 0.15s; }
    .swiper-menu-item:not(:last-child) .swiper-menu-button:after {
      background: #E9E9E9;
      bottom: 0;
      content: "\0020";
      display: block;
      height: 0.15em;
      left: 1.5em;
      position: absolute;
      right: 1.5em;
      transition: opacity 0.15s; }
    .swiper-menu-item.-previous .swiper-menu-button:after,
    .swiper-menu-item.-active .swiper-menu-button:after {
      opacity: 0; }
  /* ------------------------------------------------------------------------ * * Tabs
\* ------------------------------------------------------------------------ */
  .tab-wrapper.-accordion > .tab-menu-button {
    display: none; }
  .tab-content {
    padding: 2.75em 0em; }
  /* ------------------------------------------------------------------------ * * Tribe Events
\* ------------------------------------------------------------------------ */
  #tribe-events-bar #tribe-bar-form .tribe-bar-filters .tribe-bar-date-filter {
    float: left;
    padding-right: 10px;
    width: 50%; }
  #tribe-events-bar #tribe-bar-form .tribe-bar-filters .tribe-bar-search-filter {
    float: right;
    padding-left: 10px;
    width: 50%; }
  #tribe-events-bar #tribe-bar-form .tribe-bar-filters .tribe-bar-submit {
    clear: both; }
  .tribe-events-calendar {
    font-size: 1.125em;
    line-height: 1.66667em; }
  .tribe-events-calendar td {
    height: 6.66667em;
    text-align: left; }
  .tribe-events-calendar td.tribe-events-has-events:after {
    display: none; }
  .tribe-events-calendar div[id^="tribe-events-event-"]:not(.tribe-events-last) {
    border-bottom: 0.11111em solid #E9E9E9;
    margin-bottom: 0.33333em;
    padding-bottom: 0.33333em; }
  .tribe-events-calendar .tribe-events-month-event-title {
    display: block; }
  .tribe-mobile-container {
    display: none; }
  /* ------------------------------------------------------------------------ * * Widget Card
\* ------------------------------------------------------------------------ */
  .widget-card .text, .widget-card .single-tribe_events .content .tribe-events-cal-links, .single-tribe_events .content .widget-card .tribe-events-cal-links {
    font-size: 1em; }
  .widget-card .ninja-forms-cont .ninja-forms-field.nf-desc menu,
  .widget-card .ninja-forms-cont .ninja-forms-field.nf-desc ol,
  .widget-card .ninja-forms-cont .ninja-forms-field.nf-desc p,
  .widget-card .ninja-forms-cont .ninja-forms-field.nf-desc table,
  .widget-card .ninja-forms-cont .ninja-forms-field.nf-desc ul {
    font-size: 1em; }
  /* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------ * * Page
\* ------------------------------------------------------------------------ */
  html,
  body {
    height: auto;
    overflow: auto;
    overflow-x: hidden; }
  html.-nav-active,
  .-nav-active body {
    height: auto;
    overflow: auto;
    width: auto; }
  .-nav-active .page-wrapper {
    box-shadow: none;
    height: auto;
    left: auto; }
  .page-wrapper:before {
    display: none; }
  /* ------------------------------------------------------------------------ * * Toolbar
\* ------------------------------------------------------------------------ */
  .toolbar-wrapper {
    font-size: 1em;
    padding-bottom: 0;
    padding-top: 0; }
  .toolbar {
    display: block; }
  .toolbar .search-form {
    border-radius: 0; }
  .toolbar-tagline {
    color: #FFFFFF;
    font-family: "museo-slab", "Museo Slab", "Times New Roman", "Times", serif;
    font-size: 1em;
    font-style: italic;
    font-weight: 300;
    margin: 0;
    padding: 0.71875em 0em; }
  .toolbar .menu-wrapper {
    float: left; }
  .toolbar .button, .toolbar .tribe-events-button {
    box-shadow: none;
    float: left; }
  /* ------------------------------------------------------------------------ * * Header
\* ------------------------------------------------------------------------ */
  .header-wrapper {
    padding-bottom: 0;
    padding-top: 0.625em; }
    .-nav-active .header-wrapper {
      position: relative; }
  .header .logo {
    float: left;
    margin-top: 1.48438em;
    max-width: 10.9375em; }
  .header .menu-wrapper.-nav .menu-list:not(.-sub-menu) {
    font-size: 0.875em;
    margin: 1.25em -0.6875em 0em 0em; }
  .header .menu-wrapper.-nav .menu-list:not(.-sub-menu) > .menu-item {
    padding-bottom: 2em; }
  .header .menu-wrapper.-nav .menu-item a {
    padding: 0.75em 1em 0.625em; }
  /* ------------------------------------------------------------------------ * * Slideshow
\* ------------------------------------------------------------------------ */
  .slideshow-wrapper {
    font-size: 1.172289214406369vw; }
  .slideshow {
    position: relative; }
  .slideshow .swiper-container {
    float: left;
    width: 67.14285714285714%; }
  /* ------------------------------------------------------------------------ * * Footer
\* ------------------------------------------------------------------------ */
  .footer-wrapper {
    padding: 4.375em 1.25em; }
  .footer_title {
    font-size: 1.5625em; }
  .footer_text {
    font-size: 1em; }
  .footer_logo {
    margin: 0em 1.5625em 0em 0em;
    width: 7.8125em; }
  .footer_logo.-alt {
    width: 6.875em; }
  .footer_address .address_text {
    font-size: 0.875em; }
  .footer_copyright,
  .footer_weblinx {
    clear: none;
    font-size: 0.875em; }
  .footer_copyright {
    text-align: left; } }

@media screen and (min-width: 53.3125rem) {
  /* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------ * * Hiders
\* ------------------------------------------------------------------------ */
  .hide-m {
    display: none !important; }
  .show-m {
    display: block !important; }
  /* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------ * * Header
\* ------------------------------------------------------------------------ */
  .header .logo {
    margin-top: 1.34375em;
    max-width: 16.5625em; }
  .header .menu-wrapper.-nav .menu-list:not(.-sub-menu) {
    font-size: 0.875em;
    margin: 1.33333em -0.69444em 0em 0em; } }

@media screen and (min-width: 60rem) {
  /* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------ * * Hiders
\* ------------------------------------------------------------------------ */
  .hide-l {
    display: none !important; }
  .show-l {
    display: block !important; }
  /* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------ * * Header
\* ------------------------------------------------------------------------ */
  .header .logo {
    margin-top: 1.48438em;
    max-width: 18.75em; }
  .header .menu-wrapper.-nav .menu-list:not(.-sub-menu) {
    font-size: 1em;
    margin: 1.25em -0.625em 0em 0em; } }

@media screen and (min-width: 64rem) {
  /* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------ * * Callouts
\* ------------------------------------------------------------------------ */
  .callouts {
    font-size: 1em; }
  /* ------------------------------------------------------------------------ * * Hiders
\* ------------------------------------------------------------------------ */
  .hide-xl {
    display: none !important; }
  .show-xl {
    display: block !important; }
  /* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------ * * Header
\* ------------------------------------------------------------------------ */
  .header .logo {
    margin-top: 2.03125em;
    max-width: 17.5em; }
  .header .menu-wrapper.-nav .menu-list:not(.-sub-menu) {
    font-size: 1.125em;
    margin: 1.38889em -0.72222em 0em 0em; }
  /* ------------------------------------------------------------------------ * * Content
\* ------------------------------------------------------------------------ */
  .home .content-wrapper {
    padding: 0em 1.5625em; }
  .home .content {
    border-top: 0;
    padding: 2.5em 0em; }
  .content-post {
    padding: 2.1875em 2.5em; }
    .content-post:not(:last-child):not(.-full) {
      float: left;
      width: 67.28571428571429%; }
    .home .content-post {
      padding: 0em 0.3125em 0em 0em; }
    .home .content-post:not(:last-child) {
      width: 56.428571428571429%; }
    .home .content-post .article-list {
      font-size: 0.875em; }
    .-map .content-post {
      bottom: 0;
      float: none !important;
      left: 0;
      padding: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 100% !important;
      z-index: 1; }
  .content-post .swiper-container {
    margin-bottom: 2.75em; }
    .content-post .swiper-container:first-child {
      margin-top: 0.6875em; }
  .content-sidebar {
    float: right;
    padding: 2.5em 2.5em 2.5em 1.25em;
    width: 32.71428571428571%; }
    .post-header.-noimage ~ .content-sidebar {
      padding-top: 1.40625em; }
    .home .content-sidebar {
      padding: 0em 0em 0em 1.25em;
      width: 43.571428571428571%; }
    .-map .content-sidebar .widget-card {
      position: relative;
      z-index: 2; }
  .content .article-list.-related {
    padding-top: 3.75em; }
  .content .article-list.-related {
    padding-top: 3.75em; }
  .content .article-list.-related > .row:not(:last-child) {
    padding-bottom: 3.125em; }
  .content .article-list.-related > .row > [class^="col"] {
    display: flex;
    flex-direction: column;
    width: 0; }
    .content .article-list.-related > .row > [class^="col"]:nth-child(1) {
      padding-right: 1.5625em; }
    .content .article-list.-related > .row > [class^="col"]:nth-child(2) {
      padding-left: 1.5625em; }
  .content .article-list.-related > .row > [class^="col"] > .article-card.-box {
    flex: 1 1 auto;
    margin: 0; }
  .content .-events {
    font-size: 1.172289214406369vw; }
    .content .-events:not(:last-child) {
      padding-bottom: 2.5em; }
  .content .-events > [class^="col"] {
    display: flex;
    flex-direction: column; }
  .content .-events > [class^="col"]:nth-last-child(2) {
    padding-right: 0.75em; }
  .content .-events > [class^="col"]:not(:first-child):last-child {
    padding-left: 2.375em;
    padding-top: 0; }
  .content .-events > [class^="col"] > .row,
  .content .-events > [class^="col"] > .article-list {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto; }
  .content .-events > [class^="col"] > .article-list > .row {
    flex: 1 1 auto; }
  .content .-events > [class^="col"] > .row > [class^="col"],
  .content .-events > [class^="col"] > .article-list > .row > [class^="col"] {
    display: flex;
    flex-direction: column;
    width: 0; }
    .content .-events > [class^="col"] > .row > [class^="col"]:not(:last-child),
    .content .-events > [class^="col"] > .article-list > .row > [class^="col"]:not(:last-child) {
      padding-right: 3.125em; }
  .content .-events > [class^="col"] > .row > [class^="col"] > .article-card,
  .content .-events > [class^="col"] > .article-list > .row > [class^="col"] > .article-card {
    flex: 1 1 auto; } }

@media screen and (min-width: 80rem) {
  /* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------ * * Callouts
\* ------------------------------------------------------------------------ */
  .callouts-post {
    display: flex;
    flex: 1 1 auto;
    margin: 0em 1.25em 0em 0em;
    width: 75%; }
  .callouts-post .article-list-content .article-card:first-child, .callouts-post .article-list-content .article-card:nth-child(2),
  .callouts-post .article-list-content .article-card.-box:first-child,
  .callouts-post .article-list-content .article-card.-box:nth-child(2) {
    margin-right: 1.25em; }
  .callouts-post .article-list-content .article-card:last-child,
  .callouts-post .article-list-content .article-card.-box:last-child {
    display: block; }
  .callouts-sidebar {
    width: 25%; }
  /* ------------------------------------------------------------------------ * * Hiders
\* ------------------------------------------------------------------------ */
  .hide-xxl {
    display: none !important; }
  .show-xxl {
    display: block !important; }
  /* ------------------------------------------------------------------------ * * Post Header
\* ------------------------------------------------------------------------ */
  .post-header-title-wrapper {
    padding: 3.75em 5em 0em; }
    .post-header:not(.-noimage) .post-header-title-wrapper {
      padding-bottom: 1.25em; }
  /* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------ * * Header
\* ------------------------------------------------------------------------ */
  .header .logo {
    margin-top: 1.75em;
    max-width: 30em; }
  .header .menu-wrapper.-nav .menu-list:not(.-sub-menu) {
    font-size: 1.25em;
    margin: 1.4em -0.75em 0em 0em; }
  /* ------------------------------------------------------------------------ * * Content
\* ------------------------------------------------------------------------ */
  .home .content {
    padding: 5em 0em; }
  .content-post {
    padding: 4.375em 5em; }
    .home .content-post {
      padding: 0em 0.625em 0em 0em; }
  .content-sidebar {
    padding: 5em 5em 5em 2.5em; }
    .home .content-sidebar {
      padding: 0em 0em 0em 2.5em; }
  .content .-events:not(:last-child) {
    padding-bottom: 5em; } }

@media screen and (min-width: 85.375rem) {
  /* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------ * * Hiders
\* ------------------------------------------------------------------------ */
  .hide-xxxl {
    display: none !important; }
  .show-xxxl {
    display: block !important; }
  /* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------ * * Header
\* ------------------------------------------------------------------------ */
  .header .logo {
    margin-top: 2.1875em;
    max-width: 34.375em; }
  .header .menu-wrapper.-nav .menu-list:not(.-sub-menu) {
    margin-top: 1.4em; }
  .header .menu-wrapper.-nav .menu-item a {
    padding-bottom: 1.125em;
    padding-top: 1.125em; }
  /* ------------------------------------------------------------------------ * * Slideshow
\* ------------------------------------------------------------------------ */
  .slideshow-wrapper {
    font-size: 1em; }
  /* ------------------------------------------------------------------------ * * Content
\* ------------------------------------------------------------------------ */
  .content .-events {
    font-size: 1em; } }

@media screen and (min-width: 90rem) {
  /* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------ * * Hiders
\* ------------------------------------------------------------------------ */
  .hide-xxxxl {
    display: none !important; }
  .show-xxxxl {
    display: block !important; }
  /* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */ }

@media print {
  /* ------------------------------------------------------------------------ * * Base
\* ------------------------------------------------------------------------ */
  .tribe-events-calendar .tribe-events-month-event-title {
    display: block; }
  .toolbar-wrapper,
  .header .menu-button,
  .pagination-block,
  .tribe-events-button,
  .footer .menu-wrapper.-social {
    display: none; } }
