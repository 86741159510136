// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Divider
\* ------------------------------------------------------------------------ */

.divider {
    & {
        background: $background_alt;
        border: 0;
        height: remify(3);
        margin: remify(22 0, 16);
    }
}
