// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Weblinx
\* ------------------------------------------------------------------------ */

.weblinx {
    & {
        color: $light_alt;
        font-family: $body-font;
        font-size: remify(10.8, 16);
        font-weight: 700;
        line-height: normal;
        margin: 0;
        text-transform: uppercase;
    }
}

.weblinx a {
    & {
        color: $light_alt;
        text-decoration: none;
        transition: color 0.15s;
    }

    &:hover {
        color: $light;
    }
}
