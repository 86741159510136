// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Article Card
\* ------------------------------------------------------------------------ */

.article-card {
    & {
        overflow: hidden;
        width: 100%;
    }

    .article-list-item:not(:last-of-type) > &:not(.-box),
    :not([class^="col"]) > &:not(.-box):not(:last-of-type),
    [class^="col"]:not(:last-of-type) > &:not(.-box) {
        padding-bottom: remify(22, 16);
    }

    .article-list-item:not(:first-of-type) > &:not(.-box),
    :not([class^="col"]) > &:not(.-box):not(:first-of-type),
    [class^="col"]:not(:first-of-type) > &:not(.-box) {
        border-top: remify(3) solid $background_alt;
        padding-top: remify(22, 16);
    }

    &.-box {
        background: $background;
        box-shadow: remify(0 1 4.25 0.75, 16) transparentize($dark, 0.8);
        margin: 0 auto;
        max-width: remify(280, 16);
        position: relative;
    }

    :not([class^="col"]) > &.-box:not(:last-of-type),
    [class^="col"]:not(:last-of-type) > &.-box {
        margin-bottom: remify(24, 16);
    }

    :not([class^="col"]) > &.-box,
    [class^="col"] > &.-box {
        margin-bottom: remify(24, 16);
    }
}

.article-card-image {
    & {
        margin: remify(0 0 20, 16);
        position: relative;
    }

    .article-card.-box > & {
        margin: 0;
    }
}

.article-card-image img {
    & {
        display: block;
        height: auto !important;
        width: 100%;
    }
}

.article-card-image img.article-card-image-banner {
    & {
        left: 0;
        position: absolute;
        top: 0;
        width: auto;
    }
}

.article-card-content {
    .article-card.-box & {
        padding: remify(18 20, 16);
    }
}

.article-card-title {
    & {
        color: $primary;
        display: inline;
        font-family: $heading-font;
        font-size: remify(18, 16);
        font-weight: 700;
        line-height: remify(24, 20);
        margin: 0;
        text-transform: uppercase;
        transition: color 0.15s;
    }

    &:hover {
        color: $primary_alt;
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    .article-card.-box.-alt & {
        display: block;
    }
}

.article-card-date {
    & {
        color: lighten($foreground, 20.3922);
        display: inline;
        font-family: $heading-font;
        font-size: remify(15, 16);
        font-weight: 700;
        line-height: remify(20, 15);
        margin: 0;
        text-transform: uppercase;
    }

    &:hover {
        color: lighten(lighten($foreground, 20.3922), 10);
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    .article-card.-box:not(.-alt) > .article-card-image + .article-card-content > & {
        background: $dark_alt;
        box-shadow: remify(0 1 4.25 0.75, 12) transparentize($dark, 0.8);
        color: $light;
        display: block;
        font-size: remify(12, 16);
        line-height: remify(18, 12);
        position: absolute;
        right: remify(20, 12);
        text-align: center;
        transition: background 0.15s;
        top: 0;
    }

    .article-card.-box:not(.-alt) > .article-card-image + .article-card-content > &:hover {
        background: lighten($dark_alt, 10);
    }

    .article-card.-box:not(.-alt) > .article-card-image + .article-card-content > & a {
        display: block;
        padding: remify(8 16, 12);
    }

    .article-card.-box.-alt & {
        display: block;
    }
}

.article-card-date strong {
    & {
        font-weight: 700;
    }

    .article-card.-box:not(.-alt) > .article-card-image + .article-card-content & {
        display: block;
        color: $light;
        font-family: $body-font;
        font-size: remify(24, 12);
        font-weight: 300;
        line-height: remify(20, 24);
    }
}

.article-card-excerpt {
    & {
        color: $foreground;
        font-family: $body-font;
        font-size: remify(16, 16);
        font-weight: 300;
        line-height: remify(24, 16);
        margin: 0;
    }

    &:hover {
        color: lighten($foreground, 10);
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    &.-tight {
        line-height: remify(18, 16);
    }
}

.article-card-meta-group {
    &:last-of-type {
        margin-bottom: remify(20, 16);
    }
}

.article-card-meta-title {
    & {
        color: $primary;
        font-family: $heading-font;
        font-size: remify(20, 16);
        font-weight: 700;
        margin: 0;
        text-transform: uppercase;
    }

    a {
        color: inherit;
        text-decoration: none;
    }
}

.article-card-meta-list {
    & {
        color: $foreground;
        font-family: $body-font;
        font-size: remify(16, 16);
        font-weight: 300;
        list-style: none;
        line-height: remify(24, 16);
        margin: remify(0 0 24, 16);
        padding: 0;
    }

    a {
        color: inherit;
        text-decoration: none;
    }
}

.article-card-meta-list-item {
    & {
        margin: remify(8 0, 16);
    }
}
