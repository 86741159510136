// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Content
\* ------------------------------------------------------------------------ */

.heading {
    color: $primary;
    font-family: $heading-font;
    font-size: remify(25, 16);
    font-weight: 700;
    text-transform: uppercase;
    margin: remify(0 0 22, 25);

    &.-alt {
        color: $foreground;
    }

    &.-sub {
        font-size: remify(20.5, 16);
    }

    &.-small {
        font-size: remify(15.155, 16);
    }

    a {
        color: inherit;
        text-decoration: none;
        transition: color 0.15s;
    }

    &:hover a {
        color: $accent_alt;
    }

    &.-alt:hover a {
        color: $dark_alt;
    }
}

.text {
    & {
        color: $foreground;
        font-family: $body-font;
        font-size: remify(16, 16);
        font-weight: 300;
        line-height: remify(24, 16);
        margin: remify(0 0 24, 16);
    }
}

.link {
    & {
        color: $primary;
        text-decoration: none;
        transition: color 0.15s;
    }

    &:hover {
        color: $primary_alt;
    }
}

// Tablet variant

@media screen and (min-width: $s-break) {
    .heading {
        font-size: remify(45, 16);

        &.-sub {
            font-size: remify(26, 16);
        }

        &.-small {
            font-size: remify(20, 16);
        }
    }

    .text {
        font-size: remify(18, 16);
        line-height: remify(30, 18);
        margin-bottom: remify(30, 18);
    }
}
