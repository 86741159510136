// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Widget Card
\* ------------------------------------------------------------------------ */

.widget-card .text {
    font-size: remify(16, 16);
}

.widget-card .ninja-forms-cont .ninja-forms-field.nf-desc {
    menu,
    ol,
    p,
    table,
    ul {
        font-size: remify(16, 16);
    }
}
