// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Swiper
\* ------------------------------------------------------------------------ */

// import base styles

@import "../../vendor/_swiper";

// custom styles

.swiper-container {
    background: $background_alt;
    display: flex;
}

.swiper-slide {
    background: $background;
    height: auto;
    margin: 0;
}

.swiper-image {
    display: block;
    width: 100%;
}

.swiper-caption {
    background: $background;
    padding: remify(20 25, 16);
}

.swiper-caption-title {
    color: darken(saturate(adjust-hue($accent, -0.1558), 9.7222), 15.8824);
    font-family: $heading-font;
    font-size: remify(18, 16);
    font-weight: 700;
    margin: remify(0 0 2, 20);
    text-transform: uppercase;

    &:last-child {
        margin-bottom: 0;
    }
}

.swiper-caption-content .user-content {
    /* general text */
    ol,
    p,
    table,
    ul {
        font-size: remify(12, 16);
    }

    /* modifiers */
    a:not(.button) {
        color: $foreground;
        font-weight: 700;

        &:hover {
            color: lighten($foreground, 5);
            text-decoration: underline;
        }
    }

    /* special */
    & > *:last-child {
        margin-bottom: 0 !important;
    }
}

.swiper-menu {
    display: none;
}

// display the first slide in older browsers
.no-csstransforms .swiper-slide:not(:first-child) {
    display: none;
}
