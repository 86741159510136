// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Button
\* ------------------------------------------------------------------------ */

.button {
    &.-big {
        font-size: remify(20, 16);
        padding: remify(10.5 23.65 8, 20);
    }
}
