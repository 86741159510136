// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Gridly (Modified from https://github.com/IonicaBizau/gridly)
\* ------------------------------------------------------------------------ */

.row {
    display: flex;
    flex-direction: column;
}

.col {
    flex: 0 0 auto;
}

// Tablet variant

@media screen and (min-width: $s-break) {
    .row {
        flex-direction: row;

        &.-wrap {
            flex-wrap: wrap;
        }

        &.-between {
            justify-content: space-between;
        }

        &.-top {
            align-items: flex-start;
        }

        &.-center {
            align-items: center;
        }

        &.-bottom {
            align-items: flex-end;
        }

        &.-padded {
            width: calc(100% + #{remify(20, 16)});
        }

        &.-padded > [class^="col"] {
            padding-right: remify(20, 16);
        }

        [class^="col"].-padded-right {
            padding-right: remify(5, 16);
        }

        [class^="col"].-padded-left {
            padding-left: remify(5, 16);
        }
     }

    .col {
        flex: 1 1 auto;
    }

    .col-fluid {
        flex: 1 1 0%;
    }

    .col-none {
        flex: 0 0 auto;
    }

    .col-tenth {
        flex: 0 0 10%;
    }

    .col-fifth {
        flex: 0 0 20%;
    }

    .col-quarter {
        flex: 0 0 25%;
    }

    .col-third {
        flex: 0 0 33.3333334%;
    }

    .col-half {
        flex: 0 0 50%;
    }
}
