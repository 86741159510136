// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Featured Image
\* ------------------------------------------------------------------------ */

.featured-image {
    margin: 0;
}

.featured-image img {
    box-shadow: remify(0 1 4.25 0.75, 16) transparentize($dark, 0.8);
    display: block;
    height: auto !important;
    width: 100%;
}
