// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Article Card
\* ------------------------------------------------------------------------ */

.article-card {
    .article-list-item:not(:first-of-type) > &:not(.-box),
    :not([class^="col"]) > &:not(.-box):not(:first-of-type),
    [class^="col"]:not(:first-of-type) > &:not(.-box) {
        border-top: 0;
        padding-top: 0;
    }

    .row:not(:last-of-type) > [class^="col"] > &:not(.-box) {
        padding-bottom: remify(22, 16);
    }

    &.-box {
        margin-bottom: 0;
        max-width: none;
    }
}

.article-card-image {
    .article-card:not(.-box):not(.-vertical) & {
        float: left;
        padding-right: remify(25, 16);
        width: (100% / 3);
    }

    .article-card:not(.-box):not(.-vertical) & + .article-card-content {
        float: left;
        width: (100% / 3) * 2;
    }
}

.article-card-content {
    .article-card.-box & {
        padding-bottom: remify(26, 16);
        padding-top: remify(26, 16);
    }
}

.article-card-title {
    & {
        font-size: remify(22, 16);
        margin: remify(0 0 10, 22);
    }

    .article-card.-box.-alt & {
        margin-bottom: 0;
    }
}

.article-card-date {
    .article-card.-box:not(.-alt) > .article-card-image + .article-card-content > & {
        font-size: remify(14, 16);
    }

    .article-card.-box.-alt & {
        margin-bottom: remify(10, 15);
    }
}

.article-card-excerpt {
    & {
        font-size: remify(18, 16);
        line-height: remify(30, 18);
    }

    .article-card.-box & {
        font-size: remify(15, 16);
        line-height: remify(24, 16);
    }
}

.article-card-meta-group {
    & {
        float: left;
        margin-bottom: remify(20, 16);
        width: 50%;
    }

    &:nth-last-child(2) {
        clear: left;
        padding-right: remify(20, 16);
    }

    &:nth-last-child(2) + & {
        padding-left: remify(20, 16);
    }
}
