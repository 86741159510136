// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Helpers                                                                  *
\* ------------------------------------------------------------------------ */

// imports

@import "_variables";
@import "_functions";
@import "_mixins";

// generic helpers

._block {
    display: block !important;
}

._flex {
    display: flex !important;
}

._inline {
    display: inline !important;
}

._visually-hidden,
._visuallyhidden {
    left: -999em !important;
    position: absolute !important;
    top: -999em !important;
}

._accent {
    color: $accent !important;
}

._dark {
    color: $dark !important;
}

._light {
    color: $light !important;
}

._textcenter {
    text-align: center !important;
}

._textleft {
    text-align: left !important;
}

._textright {
    text-align: right !important;
}

._center {
    margin-left: auto !important;
    margin-right: auto !important;
}

._left {
    float: left !important;
}

._right {
    float: right !important;
}

._lowercase {
    text-transform: lowercase !important;
}

._uppercase {
    text-transform: uppercase !important;
}

._nomargin {
    margin: 0 !important;
}

._nopadding {
    padding: 0 !important;
}
