// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Base
\* ------------------------------------------------------------------------ */

.tribe-events-calendar .tribe-events-month-event-title {
    & {
        display: block;
    }
}

.toolbar-wrapper,
.header .menu-button,
.pagination-block,
.tribe-events-button,
.footer .menu-wrapper.-social {
    & {
        display: none;
    }
}
