// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Header
\* ------------------------------------------------------------------------ */

.header .logo {
    margin-top: remify(23.75, 16);
    max-width: remify(300, 16);
}

.header .menu-wrapper.-nav .menu-list:not(.-sub-menu) {
    font-size: remify(16, 16);
    margin: remify(20 -10 0 0, 16);
}
