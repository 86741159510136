// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Comments
\* ------------------------------------------------------------------------ */

#comments {
    padding-top: remify(20, 16);
}

.comments-title.heading {
    font-size: remify(24, 16);
    margin: remify(0 0 12, 24);
}

.comment-form-title.heading {
    font-size: remify(20, 16);
    margin: remify(0 0 10, 20);
}

.comment-input {
    display: block;
    width: 100%;
}
