// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Hiders
\* ------------------------------------------------------------------------ */

.hide-xxl {
    display: none !important;
}

.show-xxl {
    display: block !important;
}
