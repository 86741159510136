// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Menu Button
\* ------------------------------------------------------------------------ */

.menu-button {
    background: none;
    border: 0;
    color: $primary;
    font-family: $heading-font;
    font-size: remify(20, 16);
    font-weight: 700;
    height: remify(35, 20);
    line-height: normal;
    margin: 0;
    padding: 0;
    position: relative;
    text-transform: uppercase;
    transition: color 0.15s;
    transform: translateZ(0); // force GPU rendering
    width: remify(35, 20);
    z-index: 999;

    .-nav-active & {
        color: $light;
    }

    &:before,
    &:after {
        background: currentColor;
        bottom: 0;
        content: "\0020";
        display: block;
        height: remify(4, 22);
        margin: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: box-shadow 0.15s 0.15s, top 0.15s 0.15s, transform 0.15s;
        width: remify(22, 22);

        .-nav-active & {
            transition: box-shadow 0.15s, top 0.15s, transform 0.15s 0.15s;
        }
    }

    &:before {
        box-shadow: remify(0 7 0 0, 22) currentColor;
        top: remify(-15, 22);

        .-nav-active & {
            box-shadow: 0 0 0 0 transparent;
            transform: rotate(45deg);
            top: 0;
        }
    }

    &:after {
        top: remify(12, 22);

        .-nav-active & {
            transform: rotate(-45deg);
            top: 0;
        }
    }
}
