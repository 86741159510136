// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Post Footer
\* ------------------------------------------------------------------------ */

.post-footer {
    margin-top: remify(40, 16);
    padding: remify(28 30, 16);
}

.post-footer .user-content {
    ol,
    p,
    table,
    ul {
        font-size: remify(15, 16);
    }
}

.post-footer [class^="col"] {
    width: 0;

    &:not(:last-child) {
        padding-right: remify(20, 16);
    }
}
