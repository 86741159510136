// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Header
\* ------------------------------------------------------------------------ */

.header .logo {
    margin-top: remify(32.5, 16);
    max-width: remify(280, 16);
}

.header .menu-wrapper.-nav .menu-list:not(.-sub-menu) {
    font-size: remify(18, 16);
    margin: remify(25 -13 0 0, 18);
}
