// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Content
\* ------------------------------------------------------------------------ */

.content-wrapper {
    @include layer("content");

    background: url("../media/background-content.jpg") center top no-repeat lighten($dark_alt, 3.5294);
    font-size: 16px;
    font-size: remify(16);

    .home & {
        background: $background;
    }
}

.content {
    @include clear;

    background: $background;
    border-top: remify(8) solid $primary;
    margin: 0 auto;
    max-width: $site-width + px;
    max-width: remify($site-width);

    .home & {
        border-top: 0;
        padding: remify(20 25, 16);
    }
}

.content-post {
    @include clear;

    padding: remify(20 25, 16);

    .home & {
        padding-left: 0;
        padding-right: 0;
    }

    .post-header.-noimage + & {
        padding-top: 0;
    }
}

.content-post .swiper-container {
    margin: remify(0 0 22, 16);
}

.content-sidebar {
    @include clear;

    padding: remify(0 25 20, 16);

    .home & {
        padding: 0;
    }
}

.content-sidebar .widget-card:not(:last-child):not(.-alt):not(.-alt2) {
    margin-bottom: remify(60, 16);
}

// callout column spacing

.content .article-list.-related {
    padding-top: remify(40, 16);
}

// event column spacing

.content .-events {
    &:not(:last-child) {
        padding-bottom: remify(30, 16);
    }
}

// alt variatn

.content-wrapper.-alt {
    background: url("../media/background-content-alt.png") center top / 100% 100% no-repeat lighten($dark, 10.9804);

    .content {
        background: none;
    }
}
