// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Pagination
\* ------------------------------------------------------------------------ */

.pagination-block {
    & {
        @include clear;
    }
}

.pagination-block .pagination {
    & {
        @include clear;

        margin: remify(20 0 0, 16);
    }
}

.pagination-block a {
    @extend .link;
}

.pagination-block .pagination-prev {
    & {
        float: left;
        padding-right: remify(10, 16);
        max-width: 50%;
        text-align: left;
    }
}

.pagination-block .pagination-next {
    & {
        float: right;
        padding-left: remify(10, 16);
        max-width: 50%;
        text-align: right;
    }
}
