// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Content
\* ------------------------------------------------------------------------ */

// event column spacing

.content .-events {
    font-size: remify(16, 16);
}
