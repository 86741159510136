// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Slideshow
\* ------------------------------------------------------------------------ */

.slideshow-wrapper {
    font-size: 1.172289214406369vw;
}

.slideshow {
    position: relative;
}

.slideshow .swiper-container {
    float: left;
    width: 67.14285714285714%;
}
