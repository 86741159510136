// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Swiper
\* ------------------------------------------------------------------------ */

// custom styles

.swiper-wrapper {
    position: relative;
    width: 100%;
    z-index: 1;
}

.swiper-caption {
    background: transparentize($background, 0.25);
    bottom: 0;
    left: 0;
    padding: remify(20 25, 16);
    position: absolute;
    right: 0;
}

.swiper-caption-title {
    font-size: remify(26, 16);
}

.swiper-caption-content .user-content {
    /* general text */
    ol,
    p,
    table,
    ul {
        font-size: remify(16, 16);
    }
}

.swiper-menu {
    background: $background;
    bottom: 0;
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 32.85714285714286%;
    z-index: 2;
}

.swiper-menu-item {
    display: flex;
    flex: 1 1 auto;
    height: 0;
}

.swiper-menu-button {
    background: none;
    border: 0;
    display: block;
    color: $primary;
    flex: 1 1 auto;
    font-family: $heading-font;
    font-size: remify(20, 16);
    font-weight: 700;
    line-height: remify(26, 22);
    margin: 0;
    padding: remify(20 30, 22);
    position: relative;
    text-align: left;
    text-transform: uppercase;
    transition: background 0.15s, color 0.15s;
    width: 100%;

    .swiper-menu-item.-active & {
        background: $primary;
        color: $dark;
    }

    &:before {
        background: url("../media/background-triangle.svg") left center / remify(54, 20) 100% no-repeat;
        bottom: 0;
        content: "\0020";
        display: block;
        height: 100%;
        left: -999em;
        opacity: 0;
        position: absolute;
        transition: left 0s 0.15s, opacity 0.15s;
        top: 0;
        width: remify(54, 20);
    }

    .swiper-menu-item.-active &:before {
        opacity: 1;
        left: remify(-44, 20);
        transition: opacity 0.15s;
    }

    .swiper-menu-item:not(:last-child) &:after {
        background: $background_alt;
        bottom: 0;
        content: "\0020";
        display: block;
        height: remify(3, 20);
        left: remify(30, 20);
        position: absolute;
        right: remify(30, 20);
        transition: opacity 0.15s;
    }

    .swiper-menu-item.-previous &:after,
    .swiper-menu-item.-active &:after {
        opacity: 0;
    }
}
