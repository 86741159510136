// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Header
\* ------------------------------------------------------------------------ */

.header .logo {
    margin-top: remify(28, 16);
    max-width: remify(480, 16);
}

.header .menu-wrapper.-nav .menu-list:not(.-sub-menu) {
    font-size: remify(20, 16);
    margin: remify(28 -15 0 0, 20);
}
