// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Toolbar
\* ------------------------------------------------------------------------ */

.toolbar-wrapper {
    @include layer("toolbar")

    background: $page_background;
    font-size: remify(16, 16);
    padding: remify(15 25, 16);
}

.toolbar {
    @include clear;

    display: flex;
    margin: 0 auto;
    max-width: remify($site-width, 16);
}

.toolbar .search-form {
    border-radius: remify(17.5, 16);
    flex: 1 1 0%;
    margin: 0;
}

.toolbar .menu-button {
    flex: none;
    transform: translateX(#{remify(12.5, 20)});
}

.toolbar .menu-wrapper.-social .menu-list {
    color: $light;
}

.toolbar .menu-wrapper.-social .menu-item {
    border-left: remify(1, 30) solid $page_background_alt;
}

.toolbar .menu-wrapper.-social .menu-link {
    color: $light;
    padding: remify(13 12 9, 16);

    &:hover {
        color: $light_alt;
    }

    .fa {
        background: none;
        border: 0;
        color: inherit;
        font-size: remify(18, 16);
        height: auto;
        line-height: inherit;
        width: auto;
    }
}

.toolbar .menu-wrapper.-social .search-form {
    background: $background;
}

.toolbar .menu-wrapper.-social .search-form-submit {
    color: $dark;

    &:hover {
        color: $dark_alt;
    }
}

.toolbar .menu-wrapper.-social .search-form-toggle {
    color: $light;

    &:hover {
        color: $light_alt;
    }
}
