// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Header
\* ------------------------------------------------------------------------ */

.header .logo {
    margin-top: remify(35, 16);
    max-width: remify(550, 16);
}

.header .menu-wrapper.-nav .menu-list:not(.-sub-menu) {
    margin-top: remify(28, 20);
}

.header .menu-wrapper.-nav .menu-item a {
    padding-bottom: remify(22.5, 20);
    padding-top: remify(22.5, 20);
}
