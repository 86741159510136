// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Visually Hidden 
\* ------------------------------------------------------------------------ */

.visually-hidden {
    left: -999em;
    position: absolute;
    top: -999em;
}
