// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Page
\* ------------------------------------------------------------------------ */

html,
body {
    height: auto;
    overflow: auto;
    overflow-x: hidden;
}

html.-nav-active,
.-nav-active body {
    height: auto;
    overflow: auto;
    width: auto;
}

.page-wrapper {
    .-nav-active & {
        box-shadow: none;
        height: auto;
        left: auto;
    }

    &:before {
        display: none;
    }
}
