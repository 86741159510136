// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Post Header
\* ------------------------------------------------------------------------ */

.post-header-title-wrapper {
    padding: remify(60 80 0, 16);

    .post-header:not(.-noimage) & {
        padding-bottom: remify(20, 16);
    }
}
