// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Notificaiton Banners
\* ------------------------------------------------------------------------ */

.notification-banner-wrapper {
    background: $warning;

    &.-alt {
        background: $primary;
    }

    &.-alt2 {
        background: $primary_alt;
    }
}

.notification-banner {
    margin: 0 auto;
    max-width: $site-width + px;
    max-width: remify($site-width);
    min-height: remify(80, 16);
    position: relative;
}

.notification-title {
    align-items: center;
    border-bottom: remify(2) solid transparentize($dark, 0.9);
    color: $light;
    display: flex;
    font-family: $heading-font;
    font-size: remify(24, 16);
    font-weight: 700;
    line-height: remify(24, 24);
    margin: 0;
    padding: remify(24 104 24 86, 24);
    position: relative;
    text-transform: uppercase;

    .fa {
        color: $warning_alt;
        font-size: remify(42, 24);
        left: remify(30, 42);
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
    }

    .notification-banner-wrapper.-alt & .fa {
        color: lighten(darken(saturate(adjust-hue($primary, 0.3909), 0.1315), 10.1961), 5);
    }

    .notification-banner-wrapper.-alt2 & .fa {
        color: lighten(darken(desaturate(adjust-hue($primary_alt, 0.3817), 47.6794), 18.4314), 5);
    }
}

.notification-excerpt {
    color: $light;
    flex: 1 1 auto;
    font-family: $body-font;
    font-size: remify(15, 16);
    line-height: remify(24, 15);
    margin: 0;
    padding: remify(24 30, 15);

    a {
        color: $warning_alt;
        text-decoration: none;
    }

    .notification-banner-wrapper.-alt & a {
        color: lighten(darken(saturate(adjust-hue($primary, 0.3909), 0.1315), 10.1961), 5);
    }

    .notification-banner-wrapper.-alt2 & a {
        color: lighten(darken(desaturate(adjust-hue($primary_alt, 0.3817), 47.6794), 18.4314), 5);
    }
}

.notification-content {
    padding: remify(24 30, 15);

    &.user-content {
        ol,
        p,
        table,
        ul {
            color: $light;
            font-family: $body-font;
            font-size: remify(15, 16);
            line-height: remify(24, 15);
        }

        & > *:last-child {
            margin-bottom: 0;
        }

        a {
            color: $warning_alt;
            text-decoration: none;
        }

        .notification-banner-wrapper.-alt & a {
            color: lighten(darken(saturate(adjust-hue($primary, 0.3909), 0.1315), 10.1961), 5);
        }

        .notification-banner-wrapper.-alt & a {
            color: lighten(darken(desaturate(adjust-hue($primary_alt, 0.3817), 47.6794), 18.4314), 5);
        }
    }
}

.notification-button {
    background: none;
    border: 0;
    font-size: remify(24, 16);
    height: remify(96, 24);
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: remify(104, 24);

    .fa {
        color: $warning_alt;
        font-size: remify(42, 24);
        left: 50%;
        position: absolute;
        transform: translateX(-50%) translateY(-50%);
        top: 50%;
    }

    .notification-banner-wrapper.-alt & .fa {
        color: lighten(darken(saturate(adjust-hue($primary, 0.3909), 0.1315), 10.1961), 5);
    }

    .notification-banner-wrapper.-alt2 & .fa {
        color: lighten(darken(desaturate(adjust-hue($primary_alt, 0.3817), 47.6794), 18.4314), 5);
    }
}
