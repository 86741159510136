// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Callouts
\* ------------------------------------------------------------------------ */

.callouts {
    & {
        display: flex;
        font-size: remify(14, 16);
    }
}

.callouts-post {
    & {
        display: flex;
        flex: 1 1 auto;
        margin: remify(0 20 0 0, 16);
        width: (100% / 3) * 2;
    }
}

.callouts-post .article-list {
    & {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}

.callouts-post .article-list-header {
    & {
        flex: none;
    }
}

.callouts-post .article-list-content {
    & {
        display: flex;
        flex: 1 1 auto;
        width: 100%;
    }
}

.callouts-post .article-list-content .article-card,
.callouts-post .article-list-content .article-card.-box {
    & {
        flex: 1 1 auto;
        margin: 0;
        width: 0;
    }

    &:first-child {
        margin-right: remify(20, 16);
    }

    &:last-child {
        display: none;
    }
}

.callouts-sidebar {
    & {
        flex: 1 1 auto;
        width: (100% / 3);
    }
}
