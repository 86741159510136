// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Button
\* ------------------------------------------------------------------------ */

.button {
    background: $accent;
    border: 0;
    color: $light;
    display: inline-block;
    font-family: $body-font;
    font-size: remify(16);
    font-weight: 700;
    padding: remify(8 20, 16);
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: background 0.15s;

    &:hover {
        background: $accent_alt;
    }

    &.-alt {
        background: $dark;
        color: $accent;
    }

    &.-alt:hover {
        background: $dark_alt;
        color: $accent_alt;
    }

    &.-big {
        box-shadow: remify(0 1 5 1, 22) transparentize($dark, 0.8);
        font-family: $heading-font;
        font-size: remify(22);
        padding: remify(19 28 18, 22);
        text-align: left;
    }

    &.-block {
        display: block;
    }
}
