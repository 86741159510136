// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Toolbar
\* ------------------------------------------------------------------------ */

.toolbar-wrapper {
    font-size: remify(16, 16);
    padding-bottom: 0;
    padding-top: 0;
}

.toolbar {
    display: block;
}

.toolbar .search-form {
    border-radius: 0;
}

.toolbar-tagline {
    color: $light;
    font-family: $body-font;
    font-size: remify(16, 16);
    font-style: italic;
    font-weight: 300;
    margin: 0;
    padding: remify(11.5 0, 16);
}

.toolbar .menu-wrapper {
    float: left;
}

.toolbar .button {
    box-shadow: none;
    float: left;
}
