// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Content
\* ------------------------------------------------------------------------ */

.content {
    .home & {
        padding: remify(80 0, 16);
    }
}

.content-post {
    padding: remify(70 80, 16);

    .home & {
        padding: remify(0 10 0 0, 16);
    }
}

.content-sidebar {
    padding: remify(80 80 80 40, 16);

    .home & {
        padding: remify(0 0 0 40, 16);
    }
}

// event column spacing

.content .-events {
    &:not(:last-child) {
        padding-bottom: remify(80, 16);
    }
}
