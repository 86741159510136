// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Slideshow
\* ------------------------------------------------------------------------ */

.slideshow-wrapper {
    @include layer("slideshow");

    background: url("../media/background-content.jpg") center top no-repeat lighten($dark_alt, 3.5294);
    font-size: remify(16, 16);
}

.slideshow {
    @include clear;

    margin: 0 auto;
    max-width: remify($site-width);
}
