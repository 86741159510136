// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Post Footer
\* ------------------------------------------------------------------------ */

.post-footer {
    background: darken(saturate(adjust-hue($primary, 0.3909), 0.1315), 10.1961);
    padding: remify(18 20, 16);
}

.post-footer-title {
    color: $light;
    font-family: $heading-font;
    font-size: remify(22, 16);
    font-weight: 700;
    margin: 0;
    text-transform: uppercase;
}

.post-footer .user-content {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    ol,
    p,
    table,
    ul {
        color: $light;
    }

    a {
        color: lighten(desaturate(adjust-hue($primary, -0.0966), 37.2264), 26.4706);
    }

    a:hover {
        color: lighten(lighten(desaturate(adjust-hue($primary, -0.0966), 37.2264), 26.4706), 10);
    }
}

.post-footer [class^="col"] {
    padding-top: remify(20, 16);
}
