// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Content
\* ------------------------------------------------------------------------ */

.content-wrapper {
    .home & {
        padding: remify(0 25, 16);
    }
}

.content {
    .home & {
        border-top: 0;
        padding: remify(40 0, 16);
    }
}

.content-post {
    padding: remify(35 40, 16);

    &:not(:last-child):not(.-full) {
        float: left;
        width: 67.28571428571429%;
    }

    .home & {
        padding: remify(0 5 0 0, 16);
    }

    .home &:not(:last-child) {
        width: 56.428571428571429%;
    }

    .home & .article-list {
        font-size: remify(14, 16);
    }

    .-map & {
        bottom: 0;
        float: none !important; // overrides :last-child stuff
        left: 0;
        padding: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100% !important; // overrides :last-child stuff
        z-index: 1;
    }
}

.content-post .swiper-container {
    margin-bottom: remify(44, 16);

    &:first-child {
        margin-top: remify(11, 16);
    }
}

.content-sidebar {
    float: right;
    padding: remify(40 40 40 20, 16);
    width: 32.71428571428571%;

    .post-header.-noimage ~ & {
        padding-top: remify(22.5, 16);
    }

    .home & {
        padding: remify(0 0 0 20, 16);
        width: 43.571428571428571%;
    }

    .-map & .widget-card {
        position: relative;
        z-index: 2;
    }
}

// callout column spacing

.content .article-list.-related {
    padding-top: remify(60, 16);
}

.content .article-list.-related {
    padding-top: remify(60, 16);
}

.content .article-list.-related > .row {
    &:not(:last-child) {
        padding-bottom: remify(50, 16);
    }
}

.content .article-list.-related > .row > [class^="col"] {
    display: flex;
    flex-direction: column;
    width: 0;

    &:nth-child(1) {
        padding-right: remify(25, 16);
    }

    &:nth-child(2) {
        padding-left: remify(25, 16);
    }
}

.content .article-list.-related > .row > [class^="col"] > .article-card.-box {
    flex: 1 1 auto;
    margin: 0;
}

// event column spacing

.content .-events {
    font-size: 1.172289214406369vw;

    &:not(:last-child) {
        padding-bottom: remify(40, 16);
    }
}

.content .-events > [class^="col"] {
    display: flex;
    flex-direction: column;
}

.content .-events > [class^="col"]:nth-last-child(2) {
    padding-right: remify(12, 16);
}

.content .-events > [class^="col"]:not(:first-child):last-child {
    padding-left: remify(38, 16);
    padding-top: 0;
}

.content .-events > [class^="col"] > .row,
.content .-events > [class^="col"] > .article-list {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
}

.content .-events > [class^="col"] > .article-list > .row {
    flex: 1 1 auto;
}

.content .-events > [class^="col"] > .row > [class^="col"],
.content .-events > [class^="col"] > .article-list > .row > [class^="col"] {
    display: flex;
    flex-direction: column;
    width: 0;

    &:not(:last-child) {
        padding-right: remify(50, 16);
    }
}

.content .-events > [class^="col"] > .row > [class^="col"] > .article-card,
.content .-events > [class^="col"] > .article-list > .row > [class^="col"] > .article-card {
    flex: 1 1 auto;
}
