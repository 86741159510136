// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Search Form
\* ------------------------------------------------------------------------ */

.search-form {
    background: $light_alt;
    display: flex;
    font-size: remify(16, 16);
    margin: remify(0 0 16, 16);
    width: 100%;

    &.-large {
        font-size: remify(24, 16);
    }

    .search-form-wrapper.-expandable & {
        margin: 0;
        opacity: 0;
        overflow: hidden;
        position: relative;
        transition: opacity 0.35s, width 0.35s;
        width: remify(45, 16);
        z-index: 1;
    }

    .search-form-wrapper.-expandable.-active & {
        opacity: 1;
        width: remify(180, 16);
        z-index: 2;
    }

    @include placeholder {
        color: $foreground_alt;
        opacity: 1; // for Firefox
    }
}

.search-form-input {
    background: none;
    border: 0;
    color: $foreground_alt;
    flex: 1 1 auto;
    font-family: $body-font;
    font-size: 1em;
    font-weight: 300;
    line-height: normal;
    margin: 0;
    padding: remify(8 12, 16);
    text-overflow: ellipsis;
    width: 0;

    .search-form-wrapper.-expandable & {
        padding-bottom: remify(11, 16);
        padding-top: remify(12, 16);
    }
}

.search-form-submit {
    background: none;
    border: 0;
    color: $foreground;
    flex: none;
    font-size: remify(17, 16);
    margin: 0;
    padding: remify(4 14 5, 17);
    transition: color 0.15s;

    &:hover {
        color: lighten($foreground, 5);
    }

    .search-form-wrapper.-expandable & {
        font-size: remify(18, 16);
        padding: remify(12 14 9, 18);
    }
}

.search-form-toggle {
    background: none;
    border: 0;
    color: $foreground;
    display: block;
    font-size: 1em;
    min-width: remify(45, 16);
    padding: remify(13 12 9, 16);
    position: absolute;
    right: 0;
    top: 0;
    transition: color 0.15s, opacity 0.35s;
    z-index: 2;

    &:hover {
        color: lighten($foreground, 5);
    }

    .search-form-wrapper.-expandable.-active & {
        opacity: 0;
        z-index: 1;
    }

    .fa {
        font-size: remify(18, 16);
    }
}
