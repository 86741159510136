// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Post Header
\* ------------------------------------------------------------------------ */

.post-header {
    background: $background;
    position: relative;
}

.post-header-image {
    margin: 0;

    > img {
        display: block;
        height: auto !important;
        width: 100%;
    }
}

.post-header-title-wrapper {
    & {
        overflow: hidden;
        padding: remify(20 25 0, 16);
    }
}

.post-header-title {
    & {
        color: $primary;
        font-family: $heading-font;
        font-size: remify(22, 16);
        font-weight: 700;
        line-height: normal;
        margin: 0;
        text-transform: uppercase;
    }

    a {
        @extend .link;
    }
}

.post-header-title.-alt {
    & {
        font-size: remify(16, 16);
    }
}

.post-header-subtitle {
    & {
        color: $primary;
        font-family: $heading-font;
        font-size: remify(20, 16);
        font-weight: 700;
        line-height: normal;
        margin: 0;
        text-transform: uppercase;
    }

    a {
        @extend .link;
    }
}
