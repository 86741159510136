// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Footer
\* ------------------------------------------------------------------------ */

.footer-wrapper {
    & {
        padding: remify(70 20, 16);
    }
}

.footer_title {
    & {
        font-size: remify(25, 16);
    }
}

.footer_text {
    & {
        font-size: remify(16, 16);
    }
}

.footer_logo {
    & {
        margin: remify(0 25 0 0, 16);
        width: remify(125, 16);
    }

    &.-alt {
        width: remify(110, 16);
    }
}

.footer_address {
    .address_text {
        font-size: remify(14, 16);
    }
}

.footer_copyright,
.footer_weblinx {
    & {
        clear: none;
        font-size: remify(14, 16);
    }
}

.footer_copyright {
    & {
        text-align: left;
    }
}
