// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Article List
\* ------------------------------------------------------------------------ */

.article-list-header {
    &.-box {
        margin: 0;
        max-width: none;
    }
}

.article-list-header .heading.-sub {
    float: left;
    margin: remify(0 0 30, 28);
}

.article-list-header .heading.-alt.-small {
    display: block;
    float: right;
    margin: remify(8 0 34, 16)
}

.article-list-footer {
    &.-box {
        margin-bottom: 0;
        margin-left: 0;
        margin-right: 0;
        max-width: none;
    }
}
