// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Footer
\* ------------------------------------------------------------------------ */

.footer-wrapper {
    & {
        @include layer("footer");
        background: url("../media/background-footer.jpg") center top no-repeat $page_background;
        font-size: remify(16, 16);
        padding: remify(35 20, 16);
    }
}

.footer {
    & {
        @include clear;
        margin: 0 auto;
        max-width: remify($site-width, 16);
    }
}

.footer_row {
    .col,
    [class^="col-"] {
        overflow: hidden;
    }
}

.footer_title {
    & {
        color: $light;
        font-family: $heading-font;
        font-size: remify(16.875, 16);
        font-weight: 700;
        text-transform: uppercase;
        margin: remify(0 0 1.5, 16.875);
    }
}

.footer_text {
    & {
        color: $light;
        font-size: remify(10.8, 16);
    }
}

.footer_logo {
    & {
        float: left;
        margin: remify(0 22.5 30 0, 16);
        width: remify(100, 16);
    }

    &.-alt {
        width: remify(75, 16);
    }
}

.footer_address {
    .address_title {
        color: $light;
    }

    .address_text {
        color: $light;
    }
}

.footer_copyright,
.footer_weblinx {
    & {
        clear: both;
        text-align: center;
    }
}
