// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Post Header
\* ------------------------------------------------------------------------ */

.post-header-title-wrapper {
    padding: remify(35 40 0, 16);

    .post-header:not(.-noimage) & {
        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.1) 25%,rgba(0,0,0,0.2) 50%,rgba(0,0,0,0.25) 75%, rgba(0,0,0,0.25) 100%);
        bottom: 0;
        left: 0;
        padding-bottom: remify(10, 16);
        position: absolute;
        right: 0;
    }
}

.post-header-title {
    font-size: remify(42, 16);

    .post-header:not(.-noimage) .post-header-title-wrapper & {
        color: $light;
        text-shadow: remify(0 2 4.25, 45) transparentize($dark, 0.8);
    }
}

.post-header-title.-alt {
    & {
        font-size: remify(22, 16);
    }
}


.post-header-subtitle {
    font-size: remify(36, 16);

    .post-header:not(.-noimage) .post-header-title-wrapper & {
        color: $light;
        text-shadow: remify(0 2 4.25, 36) transparentize($dark, 0.8);
    }
}
