// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Normalize
\* ------------------------------------------------------------------------ */

// import base styles

@import "../../vendor/_normalize";

// custom styles

// Set everything up for box-sizing: border-box!
// http://css-tricks.com/international-box-sizing-awareness-day/

*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

// Remove arrows on numbers and date fields in Webkit

::-webkit-outer-spin-button,
::-webkit-inner-spin-button,
::-webkit-calendar-picker-indicator {
    appearance: none;
	display: none;
    margin: 0;
    -webkit-appearance: none;
}

// Remove arrows on numbers and date fields in Mozilla

input[type="number"] {
    -moz-appearance:textfield;
}

// remove border-radius from iOS inputs

input {
    border-radius: 0;
    -webkit-appearance: none;
}

// Reset italics on address

address {
    font-style: normal;
}
