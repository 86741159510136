// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Widget Card
\* ------------------------------------------------------------------------ */

.widget-card {
    @include clear;

    background: $background;
    box-shadow: remify(0 1 4.25 0.75, 16) transparentize($dark, 0.8);
    position: relative;

    &:not(:last-child) {
        margin-bottom: remify(20, 16);
    }

    &.-alt {
        align-items: center;
        display: flex;
    }

    &.-tabbed {
        background: none;
        box-shadow: none;
        padding-top: remify(49, 16);
    }
}

.widget-card a {
    color: inherit;
    text-decoration: none;
}

.widget-card-image {
    flex: none;
    margin: 0;
    width: remify(150, 16);

    .widget-card.-compact & {
        width: remify(50, 16);
    }
}

.widget-card-image img {
    display: block;
    height: auto !important;
    width: 100%;
}

.widget-card-content {
    padding: remify(18 20, 16);
    width: 100%;

    .widget-card.-compact & {
        padding: 0;
    }

    .widget-card.-scroll & {
        max-height: remify(490, 16);
        overflow: auto;
    }

    .widget-card.-tabbed & {
        background: $background;
        box-shadow: remify(0 1 4.25 0.75, 16) transparentize($dark, 0.8);

        .tab-content {
            padding: 0;
        }
    }

    .widget-card-image + & {
        flex: 1 1 auto;
    }
}

.widget-card-content > .tab-content {
    .content-wrapper:not(.-map) & {
        font-size: remify(14, 16);
    }
}

.widget-card-title {
    color: $primary;
    font-family: $heading-font;
    font-size: remify(20, 16);
    font-weight: 700;
    line-height: normal;
    margin: remify(0 0 2, 22);
    text-transform: uppercase;

    .widget-card.-compact & {
        line-height: remify(24, 22);
    }

    &:last-child {
        margin-bottom: 0;
    }

    &.-alt {
        font-size: remify(14, 16);
        text-transform: lowercase;
    }
}

.widget-card-title a {
    display: block;

    .widget-card.-compact & {
        padding: remify(10 30 5, 22);
    }

    .widget-card.-compact & .fa {
        margin-right: remify(9, 22);
    }
}

.widget-card-excerpt {
    margin: 0;
}

.widget-card .text {
    & {
        margin: remify(0 0 16, 16);
    }

    &.-shortmargin {
        margin-bottom: remify(4, 16);
    }
}

.widget-card-button {
    & {
        color: $primary;
        font-family: $heading-font;
        font-size: remify(20, 16);
        font-weight: 700;
        line-height: normal;
        margin: remify(0 0 2, 22);
        text-transform: uppercase;
    }
}

/* alt2 variant */

.widget-card.-alt2 {
    & {
        background: $primary;
    }
}
