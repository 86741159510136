// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Copyright
\* ------------------------------------------------------------------------ */

.copyright {
    color: $light_alt;
    font-family: $body-font;
    font-size: remify(10.8, 16);
    font-weight: 700;
    line-height: normal;
    margin: remify(0 0 10.8, 10.8);
    text-transform: uppercase;
}
