// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Tribe Events
\* ------------------------------------------------------------------------ */

// bar

#tribe-events-bar #tribe-bar-form .tribe-bar-filters .tribe-bar-date-filter {
    & {
        float: left;
        padding-right: 10px;
        width: 50%;
    }
}

#tribe-events-bar #tribe-bar-form .tribe-bar-filters .tribe-bar-search-filter {
    & {
        float: right;
        padding-left: 10px;
        width: 50%;
    }
}

#tribe-events-bar #tribe-bar-form .tribe-bar-filters .tribe-bar-submit {
    & {
        clear: both;
    }
}

// month

.tribe-events-calendar {
    & {
        font-size: remify(18, 16);
        line-height: remify(30, 18);
    }
}

.tribe-events-calendar td {
    & {
        height: remify(120, 18);
        text-align: left;
    }

    &.tribe-events-has-events:after {
        display: none;
    }
}

.tribe-events-calendar div[id^="tribe-events-event-"]:not(.tribe-events-last) {
    & {
        border-bottom: remify(2, 18) solid $background_alt;
        margin-bottom: remify(6, 18);
        padding-bottom: remify(6, 18);
    }
}

.tribe-events-calendar .tribe-events-month-event-title {
    & {
        display: block;
    }
}

.tribe-mobile-container {
    & {
        display: none;
    }
}
