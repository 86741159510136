// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Align
\* ------------------------------------------------------------------------ */

.aligncenter,
.alignleft,
.alignright {
    display: block;
    margin: 0 auto remify(30, 16);
}
