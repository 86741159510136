// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Mobile Navigation
\* ------------------------------------------------------------------------ */

// wrapper

.mobile-nav-wrapper {
    bottom: 0;
    display: block;
    font-size: 16px;
    font-size: remify(16);
    left: -999em;
    overflow: auto;
    padding: remify(0 0 0 67.5, 16);
    position: absolute;
    right: 999em;
    top: -999em;;
    transition: left 0s 0.15s, right 0s 0.15s, top 0s 0.15s;
    z-index: 1;

    .-nav-active & {
        left: 0;
        right: 0;
        transition: none;
        top: 0;
    }

    .-nav-active .admin-bar & {
        top: 46px;
    }

    &.-no-overflow {
        height: 100%;
        overflow: hidden;
    }
}

.mobile-nav {
    min-height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;

    .mobile-nav-wrapper.-no-overflow & {
        height: 100%;
    }
}

.mobile-nav .menu-wrapper.-social .menu-list {
    color: $primary_alt;
}

.mobile-nav .menu-wrapper.-social .menu-item:not(:last-child) {
    border-right: remify(1, 30) solid $primary_alt;
}

.mobile-nav .menu-wrapper.-social .menu-link {
    color: $primary_alt;
    padding: remify(13 12 9, 16);

    &:hover {
        color: lighten($primary_alt, 10);
    }

    .fa {
        background: none;
        border: 0;
        color: inherit;
        font-size: remify(18, 16);
        height: auto;
        line-height: inherit;
        width: auto;
    }
}

.mobile-nav .button {
    box-shadow: none;
}
