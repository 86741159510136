// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Address
\* ------------------------------------------------------------------------ */

.address {
    > .address_title {
        color: $foreground;
        font-family: $heading-font;
        font-size: remify(20, 16);
        font-weight: 700;
        line-height: normal;
        margin: 0;
        text-transform: uppercase;
    }

    > .address_text {
        color: $foreground;
        font-family: $body-font;
        font-size: remify(10.8, 16);
        font-weight: 700;
        line-height: remify(16.2, 10.8);
        margin: remify(0 0 5.4, 10.8);
        text-transform: uppercase;
    }
}
