// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Header
\* ------------------------------------------------------------------------ */

.header .logo {
    margin-top: remify(21.5, 16);
    max-width: remify(265, 16);
}

.header .menu-wrapper.-nav .menu-list:not(.-sub-menu) {
    font-size: remify(14, 16);
    margin: remify(24 -12.5 0 0, 18);
}
