// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * User Content
\* ------------------------------------------------------------------------ */

.user-content {
    /* headings */
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $primary;
        font-family: $heading-font;
        font-weight: 700;
        margin: 0;
        text-transform: uppercase;
    }

    h1 {
        font-size: remify(26, 16);
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
        font-size: remify(20, 16);
    }

    /* general text */
    ol,
    p,
    table,
    ul {
        color: $foreground;
        font-family: $body-font;
        font-size: remify(16, 16);
        font-weight: 300;
        line-height: remify(24, 16);
        margin: remify(0 0 24, 16);

        // any children should have the same font size
        ol,
        p,
        table,
        ul {
            font-size: 1em;
        }

        strong {
            font-weight: 700;
        }
    }

    /* modifiers */

    a:not(.button) {
        color: $primary;
        text-decoration: none;
        transition: color 0.15s;

        &:hover {
            color: $primary_alt;
        }
    }

    /* tables */

    table {
        display: block;
        overflow: auto;
        padding-bottom: 1px;
        position: relative;
        table-layout: fixed;
        width: 100%;
        // iOS fix
        -webkit-overflow-scrolling: touch;

        &:after {
            content: "scroll for more \00BB";
            display: block;
            font-size: remify(12, 16);
            padding-right: remify(6, 12);
            text-align: right;
        }
    }

    table td,
    table th {
        min-width: remify(200, 16);
        padding: remify(12 30 8, 16);
        vertical-align: top;
    }

    table tr:nth-of-type(even) td,
    table tr:nth-of-type(even) th {
        background: $background_alt;
    }

    table thead td,
    table thead th {
        background: $primary;
        color: $light;
        font-family: $heading-font;
        font-size: remify(20, 16);
        padding: remify(13 30 8, 20);
        text-transform: uppercase;
    }

    // last child should have no margin
    > *:last-child {
        margin-bottom: 0 !important;
    }

    strong {
        font-weight: 700;
    }

    img {
        height: auto !important;
        max-width: 100%;
        width: auto !important;
    }
}

// Tablet variant

@media screen and (min-width: $s-break) {
    .user-content {
        h1 {
            font-size: remify(36, 16);
            margin: remify(0 0 22, 36);
        }

        h2 {
            font-size: remify(28, 16);
            margin: remify(0 0 22, 28);
        }

        h3 {
            font-size: remify(24, 16);
            margin: remify(0 0 16, 24);
        }

        h4 {
            font-size: remify(22, 16);
            margin: remify(0 0 14, 22);
        }

        h5 {
            font-size: remify(20, 16);
            margin: remify(0 0 12, 20);
        }

        /* general text */
        ol,
        p,
        table,
        ul {
            font-size: remify(18, 16);
            line-height: remify(30, 18);
            margin-bottom: remify(30, 18);
        }

        /* tables */

        table {
            display: table;
            font-size: remify(15, 16);
            overflow: visible;
            padding: 0;

            &:after {
                display: none;
            }
        }

        [class^="col"]:not(:last-child) {
            padding-right: remify(20, 16);
        }
    }
}
