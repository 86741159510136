// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Header
\* ------------------------------------------------------------------------ */

.header-wrapper {
    padding-bottom: 0;
    padding-top: remify(10, 16);

    .-nav-active & {
        position: relative;
    }
}

.header .logo {
    float: left;
    margin-top: remify(23.75, 16);
    max-width: remify(175, 16);
}

.header .menu-wrapper.-nav .menu-list:not(.-sub-menu) {
    font-size: remify(14, 16);
    margin: remify(20 -11 0 0, 16);
}

.header .menu-wrapper.-nav .menu-list:not(.-sub-menu) > .menu-item {
    padding-bottom: remify(32, 16);
}

.header .menu-wrapper.-nav .menu-item a {
    padding: remify(12 16 10, 16);
}
