// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Callouts
\* ------------------------------------------------------------------------ */

.callouts-post {
    & {
        display: flex;
        flex: 1 1 auto;
        margin: remify(0 20 0 0, 16);
        width: 75%;
    }
}

.callouts-post .article-list-content .article-card,
.callouts-post .article-list-content .article-card.-box {
    &:first-child,
    &:nth-child(2) {
        margin-right: remify(20, 16);
    }

    &:last-child {
        display: block;
    }
}

.callouts-sidebar {
    & {
        width: 25%;
    }
}
