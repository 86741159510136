// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Menu List
\* ------------------------------------------------------------------------ */

.menu-wrapper {
}

.menu-title {
    color: $accent;
    font-family: $heading-font;
    font-size: remify(20, 16);
    font-weight: 700;
    margin: remify(0 0 8, 20);
    text-transform: uppercase;
}

.menu-list {
    @include clear;

    list-style: none;
    margin: 0;
    padding: 0;

    .menu-wrapper:not(.-vertical) :not(.menu-mega) > &.-sub-menu,
    .menu-wrapper:not(.-vertical) & .menu-mega {
        left: -999em;
        opacity: 0;
        position: absolute;
        top: -999em;
        transition: left 0s 0.15s, opacity 0.15s, top 0s 0.15s;
        width: remify(200, 16);
    }

    .menu-wrapper:not(.-vertical) :not(.menu-mega) .menu-item:hover > &.-sub-menu,
    .menu-wrapper:not(.-vertical) & .menu-item:hover > .menu-mega {
        left: 0;
        opacity: 1;
        top: 100%;
        transition: left 0s 0.15s, opacity 0.15s 0.15s, top 0s 0.15s;
    }

    .menu-wrapper:not(.-vertical) > .menu-list > .menu-item.-right > &.-sub-menu,
    .menu-wrapper:not(.-vertical) & .menu-item.-right .menu-mega {
        left: auto;
        right: 999em;
        transition: right 0s 0.15s, opacity 0.15s, top 0s 0.15s;
    }

    .menu-wrapper:not(.-vertical) > & > .menu-item.-right:hover > &.-sub-menu,
    .menu-wrapper:not(.-vertical) > & > .menu-item.-right:hover > .menu-mega {
        left: auto;
        right: 0;
        transition: right 0s 0.15s, opacity 0.15s 0.15s, top 0s 0.15s;
    }

    .menu-wrapper:not(.-vertical) & .menu-mega {
        display: flex;
        width: auto;
    }

    .menu-wrapper:not(.-vertical) & .menu-mega > &.-sub-menu {
        flex: none;
        width: remify(225, 16);
    }

    .menu-wrapper:not(.-vertical) &.-sub-menu &.-sub-menu {
        left: auto;
        opacity: 1;
        position: static;
        top: auto;
        width: auto;
    }
}

.menu-item {
    float: left;
    position: relative;

    .menu-wrapper.-vertical & {
        display: flex;
        float: none;
        flex-wrap: wrap;
        position: static;
    }

    .menu-wrapper:not(.-vertical) .menu-list.-sub-menu & {
        float: none;
    }
}

.menu-link {
    display: block;
    font-size: 1em;
    padding: remify(11.8165, 16);
    text-decoration: none;
    transition: color 0.15s;
    width: 100%;

    .menu-wrapper.-vertical &:nth-last-child(n+3) {
        flex: 1 1 auto;
        width: 0;
    }
}

.menu-toggle {
    background: none;
    border: 0;
    color: $foreground;
    flex: none;
    font-size: 1em;
    height: remify(43.63636363636364, 16);
    margin: 0;
    padding: 0;
    position: relative;
    text-indent: -999em;
    transition: color 0.15s;
    width: remify(43.63636363636364, 16);

    &:before {
        border-bottom: remify(4.363636363636364, 16) solid transparent;
        border-left: remify(4.363636363636364, 16) solid currentColor;
        border-top: remify(4.363636363636364, 16) solid transparent;
        content: "\0020";
        display: block;
        height: 0;
        left: 50%;
        position: absolute;
        text-indent: 0;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 0;
    }

    &:hover {
        color: lighten($foreground, 5);
    }

    &.-close {
        position: absolute;
        right: 0;
        top: 0;
    }

    &.-close:before,
    &.-close:after {
        background: currentColor;
        border: 0;
        bottom: 0;
        content: "\0020";
        display: block;
        height: remify(2.909090909090909, 22);
        margin: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transform: none;
        width: remify(16, 16);
    }

    &.-close:before {
        transform: rotate(-45deg);
    }

    &.-close:after {
        transform: rotate(45deg);
    }
}

/* nav varient */

.menu-wrapper {
    &.-nav {
        background: $light;
    }
}

.menu-list {
    .menu-wrapper.-nav:not(.-vertical) & {
        font-family: $heading-font;
        font-size: remify(22, 16);
        font-weight: 700;
        text-transform: uppercase;
    }

    .menu-wrapper.-nav:not(.-vertical) &:not(.-sub-menu) > .menu-item > &.-sub-menu,
    .menu-wrapper.-nav:not(.-vertical) &:not(.-sub-menu) > .menu-item > .menu-mega > &.-sub-menu {
        background: $background;
        font-size: remify(18, 22);
        line-height: remify(20, 18);
        padding: remify(16, 22);
        width: remify(315, 22);
    }

    .menu-wrapper.-nav:not(.-vertical) &:not(.-sub-menu) > .menu-item > .menu-mega > &.-sub-menu:first-child {
        padding-left: remify(32, 22);
    }

    .menu-wrapper.-nav:not(.-vertical) &:not(.-sub-menu) > .menu-item > .menu-mega > &.-sub-menu:last-child {
        padding-right: remify(32, 22);
    }

    .menu-wrapper.-nav:not(.-vertical) &:not(.-sub-menu) > .menu-item > .menu-mega > &.-sub-menu:only-child {
        width: remify(365, 22);
    }

    .menu-wrapper.-nav:not(.-vertical) &:not(.-sub-menu) > .menu-item:hover > &.-sub-menu,
    .menu-wrapper.-nav:not(.-vertical) &:not(.-sub-menu) > .menu-item:hover > .menu-mega {
        top: calc(100% + #{remify(8, 22)});
    }

    .menu-wrapper.-nav:not(.-vertical) &:not(.-sub-menu) > .menu-item > .menu-mega:before {
        background: $primary;
        content: "\0020";
        display: block;
        height: remify(8, 22);
        left: -999em;
        position: absolute;
        right: -999em;
        top: remify(-8, 22);
        z-index: -1;
    }

    .menu-wrapper.-nav:not(.-vertical) &:not(.-sub-menu) .menu-mega:after {
        background: transparentize(darken(desaturate(adjust-hue($primary, 0.2416), 9.9632), 4.1176), 0.1);
        bottom: 0;
        content: "\0020";
        display: block;
        left: -999em;
        position: absolute;
        right: -999em;
        top: 0;
        z-index: -1;
    }

    .menu-wrapper.-nav:not(.-vertical) &.-sub-menu &.-sub-menu {
        background: none;
        font-size: remify(16, 18);
        font-family: $body-font;
        font-weight: 300;
        padding: 0;
        text-transform: none;
        width: 100%;
    }

    /* vertical nav */

    .menu-wrapper.-nav.-vertical & {
        font-family: $heading-font;
        font-size: remify(20, 16);
        font-weight: 700;
        text-transform: uppercase;
        padding: remify(0 24, 22);
    }

    .menu-wrapper.-nav.-vertical &:not(.-sub-menu) > .menu-item > &.-sub-menu {
        border-top: remify(8, 22) solid $primary;
    }

    .menu-wrapper.-nav.-vertical &.-sub-menu {
        background: $background;
        bottom: 999em;
        height: 100%;
        font-size: 1em;
        left: -999em;
        opacity: 0;
        overflow: auto;
        padding: remify(30 25 8, 22);
        position: absolute;
        right: 999em;
        transition: bottom 0s 0.15s, left 0s 0.15s, opacity 0.15s, right 0s 0.15s, top 0s 0.15s;
        top: -999em;
        width: 100%;
    }

    .menu-wrapper.-nav.-vertical &.-sub-menu.-active {
        bottom: 0;
        left: 0;
        opacity: 1;
        position: absolute;
        right: 0;
        transition: opacity 0.15s;
        top: 0;
        z-index: 999;
    }

    // sure would be nice if I could do :not(.-sub-menu &)
    .menu-wrapper.-nav.-vertical &.-sub-menu &.-sub-menu {
        background: none;
        bottom: auto;
        font-family: $body-font;
        font-size: remify(20, 22);
        font-weight: 300;
        left: auto;
        opacity: 1;
        padding: 0;
        position: static;
        right: auto;
        text-transform: none;
        top: auto;
    }
}

.menu-item {
    .menu-wrapper.-nav:not(.-vertical) .menu-list:not(.-sub-menu) > &:after {
        border-bottom: remify(12, 22) solid $primary;
        border-left: remify(18, 22) solid transparent;
        border-right: remify(18, 22) solid transparent;
        bottom: 0;
        content: "\0020";
        display: block;
        left: 50%;
        margin-left: remify(-18, 22);
        opacity: 0;
        position: absolute;
        transition: opacity 0.15s;
    }

    .menu-wrapper.-nav:not(.-vertical) .menu-list:not(.-sub-menu) > &:hover:after {
        opacity: 1;
        transition: opacity 0.15s 0.15s;
    }

    .menu-wrapper.-nav.-vertical .menu-list:not(.-sub-menu) > &:not(:last-child) {
        border-bottom: remify(2, 22) solid $background_alt;
    }

    .menu-wrapper.-nav .menu-list.-sub-menu & {
        padding: remify(0 0 19, 22);
    }

    // sure would be nice if I could do :not(.-sub-menu &)
    .menu-wrapper.-nav .menu-list.-sub-menu .menu-list.-sub-menu & {
        padding: 0;
    }
}

.menu-link {
    .menu-wrapper.-nav & {
        color: $primary;
        padding: remify(42 35, 22);
    }

    .menu-wrapper.-nav &:hover {
        color: lighten($primary, 5);
    }

    .menu-wrapper.-nav.-vertical & {
        padding: remify(16 0 13, 22);
    }

    .menu-wrapper.-nav .menu-list.-sub-menu & {
        padding: remify(2 0, 22);
    }

    .menu-wrapper.-nav .menu-list.-sub-menu .menu-list.-sub-menu & {
        color: $foreground !important; // hacky, but overrides all the color varients
        padding: remify(3 0, 20);
    }

    .menu-wrapper.-nav .menu-list.-sub-menu .menu-list.-sub-menu &:hover {
        color: lighten($foreground, 5) !important; // hacky, but overrides all the color varients
    }
}

.menu-toggle {
    .menu-wrapper.-nav & {
        color: $primary;
        margin-right: remify(-27, 22);
    }

    .menu-wrapper.-nav &:hover {
        color: lighten($primary, 5);
    }

    .menu-wrapper.-nav &.-close {
        margin: 0;
        right: remify(4, 22);
        top: remify(16, 22);
    }
}

/* subnav varient */

.menu-wrapper {
    &.-subnav {
    }
}

.menu-list {
    .menu-wrapper.-subnav & {
        color: $primary;
        font-family: $heading-font;
        font-size: remify(22, 16);
        font-weight: 700;
        text-transform: uppercase;
    }

    .menu-wrapper.-subnav &.-sub-menu {
        color: $foreground;
        font-family: $body-font;
        font-size: remify(17, 22);
        font-weight: 300;
        width: 100%;
    }
}

.menu-link {
    .menu-wrapper.-subnav & {
        padding: remify(6 4, 22);
    }

    .menu-wrapper.-subnav &:hover {
        color: $primary_alt;
    }

    .menu-wrapper.-subnav .menu-list.-sub-menu & {
        padding: remify(6 14 2, 17);
    }

    .menu-wrapper.-subnav .menu-list.-sub-menu &:hover {
        color: lighten($foreground, 10);
    }

    .menu-wrapper.-subnav .menu-list.-sub-menu .menu-item.current_page_item & {
        background: darken(saturate(adjust-hue($primary, 0.3909), 0.1315), 10.1961);
        color: $light;
    }
}

/* social varient */

.menu-wrapper {
    &.-social {
    }
}

.menu-list {
    .menu-wrapper.-social & {
        color: $accent;
        font-family: $body-font;
        font-size: remify(16, 16);
        font-weight: 300;
        text-align: center;
        text-transform: uppercase;
    }

    .menu-wrapper.-social.-alt & {
        background: $page_background;
        color: $primary_alt;
    }

    .menu-wrapper.-social.-big & {
        font-size: remify(20, 16);
    }

    .menu-wrapper.-social.-bold & {
        font-weight: 700;
    }

    .menu-wrapper.-social.-flexed & {
        display: flex;
        font-size: remify(20, 16);
    }
}

.menu-item {
    .menu-wrapper.-social.-flexed & {
        float: none;
        flex: 1 1 auto;
        width: 0;
    }

    .menu-wrapper.-social.-alt & {
        border-left: remify(1, 30) solid $primary_alt;
    }
}

.menu-link {
    .menu-wrapper.-social & {
        color: $accent;
        min-width: remify(45, 16);
        padding: remify(6.5 6 4.5, 16);
    }

    .menu-wrapper.-social &:hover {
        color: lighten($accent, 10);
    }

    .menu-wrapper.-social.-alt & {
        color: $primary_alt;
        padding: remify(13 12 9, 16);

        &:hover {
            color: lighten($primary_alt, 10);
        }
    }

    .menu-wrapper.-social.-alt &:hover {
        color: lighten($primary_alt, 10);
    }

    .menu-wrapper.-social .menu-item.-no-icon & {
        padding-bottom: remify(10, 16);
        padding-left: remify(24, 16);
        padding-right: remify(24, 16);
    }

    .menu-wrapper.-social.-flexed & {
        min-width: 0;
        padding: remify(15.5 12 7.5, 26.66666666666667);
    }

    .menu-wrapper.-social & .fa {
        background: lighten(desaturate(adjust-hue($accent, 5.2298), 36.2224), 1.3725);
        border: 1px solid lighten(desaturate(adjust-hue($accent, 14.9427), 67.5794), 13.7255);
        color: $light;
        display: inline-block;
        font-size: remify(16, 16);
        height: remify(28, 16);
        line-height: remify(26.4, 16);
        margin: remify(0 5 0 0, 16);
        text-align: center;
        transition: background-color 0.15s, border-color 0.15s;
        width: remify(28, 16);
    }

    .menu-wrapper.-social &:hover .fa {
        background-color: lighten(lighten(desaturate(adjust-hue($accent, 5.2298), 36.2224), 1.3725), 5);
        border-color: lighten(lighten(desaturate(adjust-hue($accent, 14.9427), 67.5794), 13.7255), 5);
    }

    .menu-wrapper.-social & .fa:nth-last-child(2) {
        margin: 0;
    }

    .menu-wrapper.-social .menu-item.-facebook & .fa {
        background-color: #1C5894;
        border-color: #1B5182;
    }

    .menu-wrapper.-social .menu-item.-facebook &:hover .fa {
        background-color: lighten(#1C5894, 5);
        border-color: lighten(#1B5182, 5);
    }

    .menu-wrapper.-social .menu-item.-instagram & .fa {
        background-color: #665641;
        border-color: #45453e;
    }

    .menu-wrapper.-social .menu-item.-instagram &:hover .fa {
        background-color: lighten(#665641, 5);
        border-color: lighten(#45453e, 5);
    }

    .menu-wrapper.-social .menu-item.-other & .fa {
        background-color: $accent;
        border-color: darken(desaturate(adjust-hue($accent, 354.6067), 30.8859), 7.0588);
    }

    .menu-wrapper.-social .menu-item.-other &:hover .fa {
        background-color: lighten($accent, 5);
        border-color: lighten(darken(desaturate(adjust-hue($accent, 354.6067), 30.8859), 7.0588), 5);
    }

    .menu-wrapper.-social .menu-item.-kids-connection & .fa {
        background-color: $primary;
        border-color: darken(desaturate(adjust-hue($primary, 354.6067), 30.8859), 7.0588);
    }

    .menu-wrapper.-social .menu-item.-kids-connection &:hover .fa {
        background-color: lighten($primary, 5);
        border-color: lighten(darken(desaturate(adjust-hue($primary, 354.6067), 30.8859), 7.0588), 5);
    }

    .menu-wrapper.-social .menu-item.-youtube & .fa {
        background-color: #AE0707;
        border-color: #751C24;
    }

    .menu-wrapper.-social .menu-item.-youtube &:hover .fa {
        background-color: lighten(#AE0707, 5);
        border-color: lighten(#751C24, 5);
    }

    .menu-wrapper.-social.-alt .fa {
        background: none;
        border: 0;
        color: inherit;
        font-size: remify(18, 16);
        height: auto;
        line-height: inherit;
        width: auto;
    }
}

/* center variant */

.menu-list.-center {
    & {
        float: right;
        position: relative;
        right: 50%;
    }

    .menu-item {
        left: 50%;
        position: relative;
    }
}

/* pagination variant */

.menu-list.-pagination {
    .menu-item.-active .menu-link {
        color: $foreground;
    }

    .menu-item.-active .menu-link:hover {
        color: lighten($foreground, 10);
    }
}
