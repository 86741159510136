// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Notificaiton Banners
\* ------------------------------------------------------------------------ */

.notification-banner-wrapper {
    padding: remify(0 20, 16);
}

.notification-banner {
    display: flex;
    min-height: remify(86, 16);
}

.notification-title {
    border-bottom: 0;
    border-right: remify(2) solid transparentize($dark, 0.9);
    flex: none;
    padding: remify(24 56, 24);

    .fa {
        left: 0;
    }
}

.notification-excerpt,
.notification-content {
    flex: 1 1 auto;

    &:not(:last-child) {
        padding-right: remify(200, 16);
    }
}

.notification-button {
    height: 100%;
    min-height: remify(86, 24);
    width: remify(86, 24);
}
